import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {TableHeadFilterButtonTitleWrapper, TableHeadHiddenDropdownWrapper} from '../../../../components/Table';
import {Tailselect} from '../../../../components/Tailselect';
import {
  searchFind,
  unitsLengthDisplayConversion,
  rowApplicationTypeMapping,
  deepCopy,
  decimalLimitRegex,
} from '../../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {CustomTablePagination} from '../../../../components/CustomTablePagination';
import {TabMenuTableWrapper} from '../../../../components/TabMenuTableWrapper';

import {Table, TableHead, TableBody, TableCell, TableRow, CircularProgress, Chip} from '@mui/material';
import {BulkEditInputs} from '../../BulkEditInputs';
import {updateBulkEditImplements, setImplementsBulkEditErrors} from '../../settingsSlice';
function ImplementsTable(props) {
  const dispatch = useDispatch();

  const loading = useSelector((state) => {
    return state.settings.tableLoading.implements;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const implementsBulkEditErrors = useSelector((state) => {
    return state.settings.implementsBulkEditErrors;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const searchText = useSelector((state) => {
    return state.searchBar.searchText;
  });

  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [tableData, setTableData] = useState([]);
  const [propagateAllColumns, setPropagateAllColumns] = useState({});

  useEffect(() => {
    filterTableRows(props.tableData);
  }, [props.tableData, props.filters, searchText, loading]);

  useEffect(() => {}, [implementsBulkEditErrors]);

  function handleFilters(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    props.setFilters((values) => {
      return {...values, [select.name]: selected};
    });
  }

  function handleBulkEditTextFields(entityIdName, entityId, propertyName, propertyValue) {
    const implementHasError =
      implementsBulkEditErrors.hasOwnProperty(entityId) &&
      implementsBulkEditErrors[entityId].hasOwnProperty(propertyName);
    const implementsBulkEditCopy = deepCopy(implementsBulkEditErrors);
    let updateRowsId = [entityId];
    if (propagateAllColumns.hasOwnProperty(propertyName) && propagateAllColumns[propertyName]) {
      updateRowsId = tableData.map((implement) => {
        return implement.serialNumber;
      });
    }

    updateRowsId.forEach((implementSN) => {
      if (implementHasError) {
        delete implementsBulkEditCopy[implementSN][propertyName];
        if (Object.keys(implementsBulkEditCopy[implementSN]).length == 0) {
          delete implementsBulkEditCopy[implementSN];
        }
      }
      dispatch(
        updateBulkEditImplements({
          [entityIdName]: implementSN,
          data: {
            [propertyName]: propertyValue,
          },
        })
      );
    });
    dispatch(setImplementsBulkEditErrors(implementsBulkEditCopy));
  }

  function handleToggleBulkEditPropagateColumn(propertyName) {
    setPropagateAllColumns((value) => {
      return {
        ...value,
        [propertyName]: value.hasOwnProperty(propertyName) ? !value[propertyName] : true,
      };
    });
  }

  function renderTaskOptions(defaultVal) {
    const options = [];
    const optionsKeyValue = [];
    Object.keys(taskConfigDict).forEach((taskId) => {
      if (taskConfigDict[taskId]?.archived != true) {
        optionsKeyValue.push({
          taskId: taskId,
          name: taskConfigDict[taskId].name,
        });
      }
    });
    optionsKeyValue.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    for (let i = 0; i < optionsKeyValue.length; i++) {
      options.push({
        value: optionsKeyValue[i].taskId,
        label: optionsKeyValue[i].name,
      });
    }

    options.unshift({
      value: '',
      label: '----',
    });
    options.sort;

    return options;
  }

  function renderRowApplicationTypeOptions() {
    const res = [];
    Object.keys(rowApplicationTypeMapping).forEach((typeNum) => {
      if (typeNum != 1) {
        res.push({label: rowApplicationTypeMapping[typeNum], value: typeNum});
      }
    });
    return res;
  }

  function renderArchivedOptions() {
    return [
      {label: 'Archived', value: true},
      {label: 'Active', value: false},
    ];
  }

  function validateInputChange(value, propertyName) {
    if (propertyName == 'rowsOrWidthValue') {
      if (value == '') {
        return true;
      }
      return typeof value === 'string' && decimalLimitRegex(2).test(value) && isFinite(Number(value));
    }
    return false;
  }

  async function filterTableRows(tableData) {
    // Filter props.tablerows based on filters
    const result = tableData.filter((rowData) => {
      return rowFilter(rowData);
    });
    // Update tableData State
    if (page > parseInt(result.length / rowsPerPage)) {
      setPage(0);
    }
    setTableData(result);
  }

  function rowFilter(rowData) {
    let implementInFilter = true;
    let row = {};
    row = Object.assign(
      {},
      {
        name: rowData.name,
        rapType: rowApplicationTypeMapping[rowData.rowApplicationType],
        linkedTask: taskConfigDict[rowData?.linkedTaskId]?.name,
      }
    );
    const search = searchFind(<div>{JSON.stringify(row)}</div>, searchText.toLowerCase().trim());

    const implementSNInFilter =
      props.filters.implementSN.includes(rowData.serialNumber) || props.filters.implementSN.length == 0;
    const vinInFilter = props.filters.implementVin.includes(rowData.vin) || props.filters.implementVin.length == 0;
    const makeInFilter = props.filters.implementMake.includes(rowData.make) || props.filters.implementMake.length == 0;
    const notesInFilter =
      props.filters.implementNotes.includes(rowData.notes) || props.filters.implementNotes.length == 0;
    const implementRapTypeInFilter =
      props.filters.implementRapType.includes(rowApplicationTypeMapping[rowData.rowApplicationType]) ||
      props.filters.implementRapType.length == 0;
    const implementLinkedTaskInFilter =
      props.filters.implementLinkedTask.includes(rowData.linkedTaskId) ||
      props.filters.implementLinkedTask.length == 0 ||
      (props.filters.implementLinkedTask.includes('') && !rowData?.linkedTaskId);
    const implementStatusInFilter =
      (props.filters.implementStatus.includes('Archived') && rowData.archived) ||
      (props.filters.implementStatus.includes('Active') && !rowData.archived) ||
      props.filters.implementStatus.length == 0;
    // if implement does not have linkedTaskId property, it also show up when filter for None
    implementInFilter =
      implementSNInFilter &&
      implementRapTypeInFilter &&
      implementLinkedTaskInFilter &&
      implementStatusInFilter &&
      vinInFilter &&
      makeInFilter &&
      notesInFilter;

    return implementInFilter && search;
  }

  function generateTableHeaders() {
    return (
      <TableHead>
        <TableRow>
          <TableCell sx={{width: !props.implementsBulkEditMode ? '25%' : '14%'}}>
            <TableHeadFilterButtonTitleWrapper title={'Implement Name'}>
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#implements-settings-name-select').toggle();
                }}
              >
                <FontAwesomeIcon
                  icon='fas fa-filter'
                  style={{color: props.filters.implementSN.length > 0 && '#4e73df'}}
                />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='implements-settings-name-select'
                name={'implementSN'}
                multiple={true}
                search={true}
                value={props.filters['implementSN']}
                options={props.filterOptions['implementSN']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          <TableCell sx={{width: '14%'}}>
            <TableHeadFilterButtonTitleWrapper
              title={'Task'}
              bulkEditMode={props.implementsBulkEditMode}
              propagateAllColumns={propagateAllColumns}
              handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
              bulkEditPropertyName={'linkedTaskId'}
            >
              <button
                className='btn btn-transparent btn-sm'
                onClick={() => {
                  return tail.select('#implements-linkedtask-select').toggle();
                }}
              >
                <FontAwesomeIcon
                  icon='fas fa-filter'
                  style={{color: props.filters.implementLinkedTask.length > 0 && '#4e73df'}}
                />
              </button>
            </TableHeadFilterButtonTitleWrapper>
            <TableHeadHiddenDropdownWrapper>
              <Tailselect
                id='implements-linkedtask-select'
                name={'implementLinkedTask'}
                multiple={true}
                search={true}
                value={props.filters['implementLinkedTask']}
                options={props.filterOptions['implementLinkedTask']}
                onChange={handleFilters}
              />
            </TableHeadHiddenDropdownWrapper>
          </TableCell>
          {!smallScreen && (
            <TableCell sx={{width: '14%'}}>
              <TableHeadFilterButtonTitleWrapper
                title={'VIN'}
                bulkEditMode={props.implementsBulkEditMode}
                propagateAllColumns={propagateAllColumns}
                handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                bulkEditPropertyName={'vin'}
              >
                <button
                  className='btn btn-transparent btn-sm'
                  onClick={() => {
                    return tail.select('#implements-vin-select').toggle();
                  }}
                >
                  <FontAwesomeIcon
                    icon='fas fa-filter'
                    style={{color: props.filters.implementVin.length > 0 && '#4e73df'}}
                  />
                </button>
              </TableHeadFilterButtonTitleWrapper>
              <TableHeadHiddenDropdownWrapper>
                <Tailselect
                  id='implements-vin-select'
                  name={'implementVin'}
                  multiple={true}
                  search={true}
                  value={props.filters['implementVin']}
                  options={props.filterOptions['implementVin']}
                  onChange={handleFilters}
                />
              </TableHeadHiddenDropdownWrapper>
            </TableCell>
          )}
          {!smallScreen && (
            <TableCell sx={{width: '14%'}}>
              <TableHeadFilterButtonTitleWrapper
                title={'Make'}
                bulkEditMode={props.implementsBulkEditMode}
                propagateAllColumns={propagateAllColumns}
                handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                bulkEditPropertyName={'make'}
              >
                <button
                  className='btn btn-transparent btn-sm'
                  onClick={() => {
                    return tail.select('#implements-make-select').toggle();
                  }}
                >
                  <FontAwesomeIcon
                    icon='fas fa-filter'
                    style={{color: props.filters.implementMake.length > 0 && '#4e73df'}}
                  />
                </button>
              </TableHeadFilterButtonTitleWrapper>
              <TableHeadHiddenDropdownWrapper>
                <Tailselect
                  id='implements-make-select'
                  name={'implementMake'}
                  multiple={true}
                  search={true}
                  value={props.filters['implementMake']}
                  options={props.filterOptions['implementMake']}
                  onChange={handleFilters}
                />
              </TableHeadHiddenDropdownWrapper>
            </TableCell>
          )}
          {!smallScreen && (
            <TableCell sx={{width: '14%'}}>
              <TableHeadFilterButtonTitleWrapper
                title={'Acre Calculation'}
                bulkEditMode={props.implementsBulkEditMode}
                propagateAllColumns={propagateAllColumns}
                handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                bulkEditPropertyName={'rowApplicationType'}
              >
                <button
                  className='btn btn-transparent btn-sm'
                  onClick={() => {
                    return tail.select('#implements-settings-rowapplicationtype-select').toggle();
                  }}
                >
                  <FontAwesomeIcon
                    icon='fas fa-filter'
                    style={{color: props.filters.implementRapType.length > 0 && '#4e73df'}}
                  />
                </button>
              </TableHeadFilterButtonTitleWrapper>
              <TableHeadHiddenDropdownWrapper>
                <Tailselect
                  id='implements-settings-rowapplicationtype-select'
                  name={'implementRapType'}
                  multiple={true}
                  search={true}
                  value={props.filters['implementRapType']}
                  options={props.filterOptions['implementRapType']}
                  onChange={handleFilters}
                />
              </TableHeadHiddenDropdownWrapper>
            </TableCell>
          )}
          {!smallScreen && (
            <TableCell width='10%'>
              <TableHeadFilterButtonTitleWrapper
                title={`Width ${unitsLengthSystem == 'imperial' ? '(ft)' : '(m)'} / Row #`}
                bulkEditMode={props.implementsBulkEditMode}
                propagateAllColumns={propagateAllColumns}
                handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                bulkEditPropertyName={'rowsOrWidthValue'}
              ></TableHeadFilterButtonTitleWrapper>
            </TableCell>
          )}
          {!smallScreen && (
            <TableCell sx={{width: '15%'}}>
              <TableHeadFilterButtonTitleWrapper
                title={'Status'}
                bulkEditMode={props.implementsBulkEditMode}
                propagateAllColumns={propagateAllColumns}
                handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                bulkEditPropertyName={'archived'}
              >
                <button
                  className='btn btn-transparent btn-sm'
                  onClick={() => {
                    return tail.select('#implements-settings-status-select').toggle();
                  }}
                >
                  <FontAwesomeIcon
                    icon='fas fa-filter'
                    style={{color: props.filters.implementStatus.length > 0 && '#4e73df'}}
                  />
                </button>
              </TableHeadFilterButtonTitleWrapper>
              <TableHeadHiddenDropdownWrapper>
                <Tailselect
                  id='implements-settings-status-select'
                  name={'implementStatus'}
                  multiple={true}
                  search={true}
                  value={props.filters['implementStatus']}
                  options={props.filterOptions['implementStatus']}
                  onChange={handleFilters}
                />
              </TableHeadHiddenDropdownWrapper>
            </TableCell>
          )}
          {!smallScreen && (
            <TableCell sx={{width: !props.implementsBulkEditMode ? '14%' : '25%'}}>
              <TableHeadFilterButtonTitleWrapper
                title={'Notes'}
                bulkEditMode={props.implementsBulkEditMode}
                propagateAllColumns={propagateAllColumns}
                handleTogglePropagateAll={handleToggleBulkEditPropagateColumn}
                bulkEditPropertyName={'notes'}
              >
                <button
                  className='btn btn-transparent btn-sm'
                  onClick={() => {
                    return tail.select('#implements-notes-select').toggle();
                  }}
                >
                  <FontAwesomeIcon
                    icon='fas fa-filter'
                    style={{color: props.filters.implementNotes.length > 0 && '#4e73df'}}
                  />
                </button>
              </TableHeadFilterButtonTitleWrapper>
              <TableHeadHiddenDropdownWrapper>
                <Tailselect
                  id='implements-notes-select'
                  name={'implementNotes'}
                  multiple={true}
                  search={true}
                  value={props.filters['implementNotes']}
                  options={props.filterOptions['implementNotes']}
                  onChange={handleFilters}
                />
              </TableHeadHiddenDropdownWrapper>
            </TableCell>
          )}
          {!smallScreen && !props.implementsBulkEditMode && <TableCell sx={{width: '10%'}} />}
        </TableRow>
      </TableHead>
    );
  }

  function generateTableBody() {
    let tableBody;
    let colorFlip = false;
    if (tableData.length == 0) {
      tableBody = (
        <TableRow>
          <TableCell colSpan={100} sx={{textAlign: 'center', fontSize: '25px'}}>
            No Implements Found
          </TableCell>
        </TableRow>
      );
    } else {
      tableBody = (
        <React.Fragment>
          {(rowsPerPage > 0 ? tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : tableData).map(
            (rowData, index) => {
              colorFlip = !colorFlip;
              const rowBulkEditErrors = implementsBulkEditErrors.hasOwnProperty(rowData.serialNumber)
                ? implementsBulkEditErrors[rowData.serialNumber]
                : {};
              // Process dimensions display
              const implementDimensions = {};
              Object.keys(rowData.dimensions).forEach((key) => {
                implementDimensions[key] =
                unitsLengthSystem == 'imperial'
                    ? unitsLengthDisplayConversion(rowData.dimensions[key], 'ft')
                    : rowData.dimensions[key];
              });

              const editImplementData = {
                name: rowData.name,
                serialNumber: rowData.serialNumber,
                dimensions: implementDimensions,
                linkedTaskId: rowData.linkedTaskId,
                archived: rowData.archived,
                rowApplicationType: rowData.rowApplicationType,
                applicationRows: rowData.applicationRows,
                vin: rowData.vin,
                make: rowData.make,
                notes: rowData.notes,
                qrLabelId: rowData.qrLabelId,
                btTagId: rowData.btTagId,
              };

              let taskDisp = 'None';
              if (Object.prototype.hasOwnProperty.call(taskConfigDict, rowData.linkedTaskId)) {
                taskDisp =
                  taskConfigDict[rowData.linkedTaskId].name +
                  (taskConfigDict[rowData.linkedTaskId]?.archived ? ' - Archived' : '');
              }

              const row = (
                <TableRow
                  className={`${smallScreen ? 'hoverPls' : ''}`}
                  onClick={() => {
                    // CLICK TO EDIT
                    if (smallScreen) {
                      props.handleEditModalOpen(true);
                      props.handleUpdateEditImplement(editImplementData);
                    }
                  }}
                  key={index}
                  style={{backgroundColor: colorFlip ? 'rgba(242, 242, 242)' : 'rgba(255, 255, 255, 0)'}}
                >
                  {!props.implementsBulkEditMode ? (
                    <TableCell>
                      <div className='font-weight-bold'>{rowData.name}</div>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <BulkEditInputs
                        type='text'
                        handleBulkEditUpdate={handleBulkEditTextFields}
                        bulkEditStateName='bulkEditImplements'
                        entityId={rowData.serialNumber}
                        entityIdName='serialNumber'
                        defaultValue={rowData.name}
                        propertyName='name'
                        submissionErrors={rowBulkEditErrors['name']}
                      />
                    </TableCell>
                  )}
                  {!props.implementsBulkEditMode ? (
                    <TableCell>
                      <div>{taskDisp}</div>
                    </TableCell>
                  ) : (
                    <TableCell>
                      <BulkEditInputs
                        type='select'
                        handleBulkEditUpdate={handleBulkEditTextFields}
                        bulkEditStateName='bulkEditImplements'
                        entityId={rowData.serialNumber}
                        entityIdName='serialNumber'
                        renderOptions={renderTaskOptions}
                        defaultValue={rowData.linkedTaskId}
                        propertyName='linkedTaskId'
                        submissionErrors={rowBulkEditErrors['linkedTaskId']}
                      />
                    </TableCell>
                  )}
                  {!smallScreen &&
                    (!props.implementsBulkEditMode ? (
                      <TableCell>
                        <div>{rowData.vin}</div>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <BulkEditInputs
                          type='text'
                          handleBulkEditUpdate={handleBulkEditTextFields}
                          bulkEditStateName='bulkEditImplements'
                          entityId={rowData.serialNumber}
                          entityIdName='serialNumber'
                          defaultValue={rowData.vin}
                          propertyName='vin'
                          submissionErrors={rowBulkEditErrors['vin']}
                        />
                      </TableCell>
                    ))}
                  {!smallScreen &&
                    (!props.implementsBulkEditMode ? (
                      <TableCell>
                        <div>{rowData.make}</div>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <BulkEditInputs
                          type='text'
                          handleBulkEditUpdate={handleBulkEditTextFields}
                          bulkEditStateName='bulkEditImplements'
                          entityId={rowData.serialNumber}
                          entityIdName='serialNumber'
                          defaultValue={rowData.make}
                          propertyName='make'
                          submissionErrors={rowBulkEditErrors['make']}
                        />
                      </TableCell>
                    ))}
                  {!smallScreen &&
                    (!props.implementsBulkEditMode ? (
                      <TableCell>
                        <div>
                          {rowApplicationTypeMapping[rowData.rowApplicationType]}
                          {smallScreen && (
                            <div>
                              <sub>
                                {rowData.rowApplicationType == 2
                                  ? `${rowData.applicationRows} Rows`
                                  : `${implementDimensions.width.toFixed(2)} ${unitsLengthSystem == 'imperial' ? 'ft' : 'm'}`}
                              </sub>
                            </div>
                          )}
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <BulkEditInputs
                          type='select'
                          handleBulkEditUpdate={handleBulkEditTextFields}
                          bulkEditStateName='bulkEditImplements'
                          entityId={rowData.serialNumber}
                          entityIdName='serialNumber'
                          renderOptions={renderRowApplicationTypeOptions}
                          defaultValue={rowData.rowApplicationType}
                          propertyName='rowApplicationType'
                          submissionErrors={rowBulkEditErrors['rowApplicationType']}
                        />
                      </TableCell>
                    ))}
                  {!smallScreen &&
                    (!props.implementsBulkEditMode ? (
                      <TableCell>
                        <div>
                          {rowData.rowApplicationType == 2
                            ? `${rowData.applicationRows} Rows`
                            : `${implementDimensions.width.toFixed(2)} ${unitsLengthSystem == 'imperial' ? 'ft' : 'm'}`}
                        </div>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <BulkEditInputs
                          type='text'
                          handleBulkEditUpdate={handleBulkEditTextFields}
                          bulkEditStateName='bulkEditImplements'
                          entityId={rowData.serialNumber}
                          inputValidation={validateInputChange}
                          entityIdName='serialNumber'
                          defaultValue={rowData.rowsOrWidthValue}
                          propertyName='rowsOrWidthValue'
                          submissionErrors={rowBulkEditErrors['rowsOrWidthValue']}
                        />
                      </TableCell>
                    ))}
                  {!smallScreen &&
                    (!props.implementsBulkEditMode ? (
                      <TableCell>
                        {rowData?.archived ? (
                          <Chip label='Archived' color='error' />
                        ) : (
                          <Chip label='Active' color='success' />
                        )}
                      </TableCell>
                    ) : (
                      <TableCell>
                        <BulkEditInputs
                          type='select'
                          handleBulkEditUpdate={handleBulkEditTextFields}
                          bulkEditStateName='bulkEditImplements'
                          entityId={rowData.serialNumber}
                          entityIdName='serialNumber'
                          renderOptions={renderArchivedOptions}
                          defaultValue={rowData.archived}
                          propertyName='archived'
                          submissionErrors={rowBulkEditErrors['archived']}
                        />
                      </TableCell>
                    ))}
                  {!smallScreen &&
                    (!props.implementsBulkEditMode ? (
                      <TableCell>
                        <div>{rowData.notes}</div>
                      </TableCell>
                    ) : (
                      <TableCell>
                        <BulkEditInputs
                          type='text'
                          handleBulkEditUpdate={handleBulkEditTextFields}
                          bulkEditStateName='bulkEditImplements'
                          entityId={rowData.serialNumber}
                          entityIdName='serialNumber'
                          defaultValue={rowData.notes}
                          propertyName='notes'
                          submissionErrors={rowBulkEditErrors['notes']}
                        />
                      </TableCell>
                    ))}
                  {!smallScreen && !props.implementsBulkEditMode && (
                    <TableCell>
                      <button
                        className='btn btn-light'
                        style={{backgroundColor: 'transparent', borderColor: 'transparent'}}
                        onClick={() => {
                          // CLICK TO EDIT
                          props.handleEditModalOpen(true);
                          props.handleUpdateEditImplement(editImplementData);
                        }}
                      >
                        <FontAwesomeIcon icon='fa fa-edit' />
                      </button>
                    </TableCell>
                  )}
                </TableRow>
              );
              return row;
            }
          )}
        </React.Fragment>
      );
    }

    if (loading) {
      tableBody = (
        <TableRow>
          <TableCell colSpan={100}>
            <CircularProgress className='mt-4 mx-auto d-block' size={80} />
          </TableCell>
        </TableRow>
      );
    }
    return tableBody;
  }

  function generateTable() {
    return (
      <Table className='ic-mui-table' size='small' stickyHeader aria-label='simple table'>
        {generateTableHeaders()}
        <TableBody>{generateTableBody()}</TableBody>
      </Table>
    );
  }

  return (
    <TabMenuTableWrapper
      table={generateTable()}
      pagination={
        <CustomTablePagination
          count={tableData.length}
          rowsPerPage={rowsPerPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          setPage={setPage}
        />
      }
    />
  );
}

export {ImplementsTable};
