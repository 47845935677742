import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties} from '../../../app/utils';
import {genVehiclesWithoutTasks} from '../dashboardDataProcessing';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportTooltipRowStyle,
} from '../dashboardUtils';
import {
  CircularProgress,
  Grid,
  Box,
} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

function KpiMissingTasks(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });

  const [vehiclesWithoutTaskData, setVehiclesWithoutTaskData] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [displayedAxis, setDisplayedAxis] = useState('Hrs');
  const [missingTaskFilters, setMissingTaskFilters] = useState({
    missingTaskVehicleSNs: [],
  });

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [operationsData, missingTaskFilters]);

  useEffect(() => {
    if (props?.pageFilters) {
      setMissingTaskFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageFilters]);

  function generateData() {
    // Generate data
    const vehiclesWithoutTaskDataTemp = genVehiclesWithoutTasks(operationsData.vehiclesObject, missingTaskFilters);
    if (Object.keys(operationsData.regionsObject).length > 0) {
      setVehiclesWithoutTaskData(vehiclesWithoutTaskDataTemp);
    } else {
      setVehiclesWithoutTaskData(null);
    }

    const datasets = [];
    vehiclesWithoutTaskDataTemp.datasets.forEach((dataset) => {
      if (dataset.label == displayedAxis) {
        datasets.push(dataset);
      }
    });
    setDatasets(datasets);
  }

  function handleTasksFilters(value, selectName) {
    setMissingTaskFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function missingTaskGraph() {
    return (
      <div style={{height: '60vh', minHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={{labels: vehiclesWithoutTaskData.labels, datasets: datasets}}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: 'Vehicles Without Tasks',
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              leftAxis: {
                title: {text: 'Hours Missing Task', display: true},
                display: displayedAxis == 'Hrs',
                type: 'linear',
                position: 'left',
              },
              rightAxis: {
                title: {text: 'Acreage Missing Task', display: true},
                display: displayedAxis == 'Ac',
                type: 'linear',
                position: 'left',
              },
              x: {
                ticks: {
                  autoSkip: vehiclesWithoutTaskData.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 90,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: 'Vehicles',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiMissingTasks' />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!vehiclesWithoutTaskData ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            missingTaskGraph()
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Tooltip and Add to Scorecard*/}
      <Box sx={StatsViewReportTooltipRowStyle}>
        {/* Tooltip */}
        <KpiTooltip selectedSubset='kpiMissingTasks' />
        {/* Add to Scorecard Button & Modal */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <ScorecardSelectHolder autofillPageName={'Missing Tasks'} pageFilters={missingTaskFilters} />
        </Box>
      </Box>

      {!vehiclesWithoutTaskData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* Filters */}
          <StatsViewReportFiltersWrapper key={`missingTaskFilter`}>
            <IntelliSearchSelect
              inputLabel={"Vehicles"}
              allNoneEnabled
              id='dashboard-tasks-missingTaskVehicleSNs-filter'
              name='missingTaskVehicleSNs'
              multiple={true}
              search={true}
              value={missingTaskFilters.missingTaskVehicleSNs}
              options={filterOptions.tasks.missingTaskVehicleSNs.map((opt) => {
                return {
                  "label":opt.text, "value":opt.value
                }
              })}
              onChange={(s) => {
                handleTasksFilters(s, "missingTaskVehicleSNs");
              }}
              placeholder={'Vehicle Filter'}
            />
          </StatsViewReportFiltersWrapper>
          {/* Graph and XY Axis Control */}
          <Grid container sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}} spacing={0}>
            {/* Graph */}
            <Grid item xs={10} sx={{textAlign: 'center', alignItems: 'center'}}>
              {missingTaskGraph()}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiMissingTasks};
