import React, {useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useSelector} from 'react-redux';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalWideColumnSection,
  ModalBody,
} from '../../components/Modal';
import {
  capitalizeFirstLetter, 
  formatTime,
  unitsAreaConversion,
} from '../../app/utils';
import {DateTime} from 'luxon';

function CoverageDataModal(props) {
  const initialValues = useSelector((state) => {
    return state.coverageModal;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const dates = useSelector((state) => {
    return state.cropview.dates;
  });
  const todayOnly = useSelector((state) => {
    return state.cropview.todayOnly;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.cropview.vehicleSNDict;
  });
  const taskConfigIdDict = useSelector((state) => {
    return state.cropview.taskConfigIdDict;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });

  const [unitsArea, setUnitsArea] = useState('ha');
  useEffect(() => {
    // Determine area units based on customer area units system
    if (unitsAreaSystem == 'acre') {
      setUnitsArea('ac');
    } else {
      setUnitsArea('ha');
    }
  }, [unitsAreaSystem]);

  if (initialValues.type == 'equipment') {
    return createPortal(
      <ModalFramework id='cropview-coverage-data-modal'>
        <ModalHeader title='Coverage Details' />
        <ModalBody>
          <ModalRowSection>
            <ModalWideColumnSection>
              <div className='font-weight-bold border-bottom'>Equipment</div>
              <div className='small'>{initialValues.name}</div>
            </ModalWideColumnSection>
          </ModalRowSection>
          <ModalRowSection>
            <ModalWideColumnSection>
              <div className='font-weight-bold border-bottom'>Time</div>
              <div className='small'>
                <span className='font-weight-bold'>In Field Time: </span>
                <span>{initialValues.timeInField}</span>
              </div>
              {customerSettings.general.outOfFieldTime && (
                <div className='small'>
                  <span className='font-weight-bold'>Out of Field Time: </span>
                  <span>{initialValues.timeOutField}</span>
                </div>
              )}
              {customerSettings.general.idleTime && (
                <div className='small'>
                  <span className='font-weight-bold'>Idle Time: </span>
                  <span>{initialValues.timeIdle}</span>
                </div>
              )}
              <div className='small'>
                <span className='font-weight-bold'>Total Time: </span>
                <span>{initialValues.timeTotal}</span>
              </div>
            </ModalWideColumnSection>
          </ModalRowSection>
          <ModalRowSection>
            <ModalWideColumnSection>
              <div className='font-weight-bold border-bottom'>
                {unitsAreaSystem == 'acre' ? 'Acreage' : 'Hectares'}
              </div>
              <div className='small'>{initialValues.coverage}</div>
            </ModalWideColumnSection>
          </ModalRowSection>
          {todayOnly && (
            <ModalRowSection>
              <ModalWideColumnSection>
                <div className='font-weight-bold border-bottom'>Task</div>
                <div className='small'>{initialValues.taskName}</div>
              </ModalWideColumnSection>
            </ModalRowSection>
          )}
        </ModalBody>
        <ModalFooter />
      </ModalFramework>,
      document.getElementById('app')
    );
  }

  return createPortal(
    <ModalFramework id='cropview-coverage-data-modal'>
      <ModalHeader title='Coverage Details' />
      <ModalBody>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>{capitalizeFirstLetter(initialValues.type)}</div>
            <div className='small'>{initialValues.zoneName}</div>
          </ModalWideColumnSection>
        </ModalRowSection>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Coverage</div>
            <div>
              <div className='d-inline small'>Time: </div>
              <div className='d-inline small'>{initialValues.zoneTimeTotal}</div>
            </div>
            <div>
              <div className='d-inline small'>Area: </div>
              <div className='d-inline small'>{initialValues.coverage}</div>
            </div>
          </ModalWideColumnSection>
        </ModalRowSection>
        <ModalRowSection>
          <ModalWideColumnSection>
            <div className='font-weight-bold border-bottom'>Equipment Usage</div>
            <div>{listEquipment()}</div>
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter />
    </ModalFramework>,
    document.getElementById('app')
  );

  function listEquipment() {
    return Object.keys(initialValues.fullEquipmentDict).map((vehicleSN, indexEquip) => {
      const vehicle = initialValues.fullEquipmentDict[vehicleSN];
      const vehicleName = vehicleSNDict[vehicleSN].name;

      return Object.keys(vehicle.taskList).map((taskId, indexTask) => {
        const task = vehicle.taskList[taskId];
        let taskName = '';
        if (taskConfigIdDict[taskId]) {
          taskName = taskConfigIdDict[taskId].name;
        }

        // Calculate converted acreage & rate
        let vehicleAcreageRate = 0;
        const taskAcreageConverted = unitsAreaConversion(task.acreTotal, unitsArea, 'ac');
        if (task.timeTotal) {
          vehicleAcreageRate = (taskAcreageConverted / task.timeTotal) * 60;
        }

        const entryTime = DateTime.fromISO(task.entryTime).setZone(customerSettings.general.timeZone);
        const exitTime = DateTime.fromISO(task.exitTime).setZone(customerSettings.general.timeZone);
        const selectedDateRangeSameDay = DateTime.fromISO(dates.start)
          .setZone(customerSettings.general.timeZone)
          .hasSame(DateTime.fromISO(dates.end).setZone(customerSettings.general.timeZone), 'day');
        const entryExitSameDay = entryTime.hasSame(exitTime, 'day');
        return (
          <div key={indexTask}>
            <span className='font-weight-bold small'>{vehicleName}</span>
            <span className='font-weight-bold small'>
              {taskName ? ' | ' : ''}
              {taskName}:{' '}
            </span>
            <span className='small'>{formatTime(task.timeTotal)}</span>

            {/* Create coverage values display */}
            <span className='small'> | {Math.round(taskAcreageConverted * 100) / 100} {unitsArea}</span>
            <span className='small'> | {Math.round(vehicleAcreageRate * 100) / 100} {`${unitsArea}/hr`}</span>

            {/* Create average speed display */}
            {customerSettings.cropview.vehicleAverageSpeedEnabled && userSettings.general.units == 'imperial' ? (
              <span className='small'> | {(vehicle.avgSpeed * 0.621).toFixed(1)} mph</span>
            ) : (
              customerSettings.cropview.vehicleAverageSpeedEnabled &&
              userSettings.general.units == 'metric' && (
                <span className='small'> | {vehicle.avgSpeed.toFixed(1)} kph</span>
              )
            )}
            {customerSettings.general.zoneEntryAndExitTimeEnabled &&
              (selectedDateRangeSameDay && entryExitSameDay ? (
                <span className='small'>
                  {' '}
                  | {entryTime.toFormat('h:mm a')} - {exitTime.toFormat('h:mm a')}
                </span>
              ) : (
                <span className='small'>
                  {' '}
                  | {entryTime.toFormat('dd/LL/yy h:mm a')} - {exitTime.toFormat('dd/LL/yy h:mm a')}
                </span>
              ))}
          </div>
        );
      });
    });
  }
}

export {CoverageDataModal};
