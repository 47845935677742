import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import DoughnutChart from '../../../components/DoughnutChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties} from '../../../app/utils';
import {genMissingTaskData, genTasksLoggedByZone} from '../dashboardDataProcessing';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportTooltipRowStyle,
} from '../dashboardUtils';
import {
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Grid,
  Box,
} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

function KpiTasksLogged(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const [selectedZoneLevel, setSelectedZoneLevel] = useState('Region');
  const [missingTaskData, setMissingTaskData] = useState(null);
  const [taskLoggedData, setTaskLoggedData] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  const [taskFilters, setTaskFilters] = useState({
    fields: [],
    fieldsCount: '10',
    fieldsSort: 'Desc',
    regions: [],
    regionsCount: '10',
    regionsSort: 'Desc',
  });

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [operationsData, taskFilters, selectedZoneLevel]);

  useEffect(() => {
    if (props.pageStates?.selectedZoneLevel) {
      setSelectedZoneLevel(props.pageStates?.selectedZoneLevel);
    } else {
      setSelectedZoneLevel('Region');
    }
    if (props?.pageFilters) {
      setTaskFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageStates, props.pageFilters]);

  function generateData() {
    // Generate data
    const missingTaskZonesData = genMissingTaskData(
      operationsData.regionsObject,
      operationsData.fieldsObject,
      operationsData.blocksObject,
      taskFilters
    );
    const missingTaskDataTemp = missingTaskZonesData[selectedZoneLevel];
    if (Object.keys(operationsData.regionsObject).length > 0) {
      setMissingTaskData(missingTaskDataTemp);
    } else {
      setMissingTaskData(null);
    }

    const totalHoursTemp = missingTaskZonesData[`${selectedZoneLevel}TotalHours`];
    setTotalHours(totalHoursTemp);

    const taskZonesData = genTasksLoggedByZone(
      operationsData.regionsObject,
      operationsData.fieldsObject,
      operationsData.blocksObject,
      taskFilters
    );
    const taskLoggedDataTemp = taskZonesData[selectedZoneLevel];
    setTaskLoggedData(taskLoggedDataTemp);
  }

  function handleTasksFilters(value, selectName) {
    setTaskFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function handleTasksFiltersOld(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    const value = select.multiple ? selected : selected[0];
    const name = select.name;
    setTaskFilters((values) => {
      return {...values, [name]: value};
    });
  }

  function taskLoggedDoughnutGraph() {
    return (
      <div style={{height: '50vh', maxHeight: '400px'}}>
        {/* Chart */}
        <DoughnutChart
          data={missingTaskData}
          options={{
            plugins: {
              datalabels: {
                color: '#fff', // Label text color
                font: {
                  size: 16,
                  weight: 'bold',
                },
                formatter: (value, context) => {
                  if (value == 0 || totalHours == 0) {
                    return '';
                  }
                  return Math.round((value / totalHours) * 100) + '%';
                },
              },
              title: {
                display: true,
                text: 'Tasks Logged vs. Missing Task',
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  function taskLoggedBarGraph() {
    return (
      <div style={{height: '40vh', minHeight: '400px'}}>
        <BarChart
          data={taskLoggedData}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                display: true,
                text: `Task Logged % vs. ${selectedZoneLevel}`,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                display: false,
              },
            },
            scales: {
              y: {
                title: {
                  text: '% Task Logged',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                ticks: {
                  callback: function (val, index) {
                    return this.getLabelForValue(val) + ' %';
                  },
                },
              },
              x: {
                title: {
                  text: String(selectedZoneLevel),
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiTasksLogged' />
        </div>
        {!missingTaskData || !taskLoggedData ? (
          <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
        ) : (
          <React.Fragment>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto pb-3'>
              {taskLoggedDoughnutGraph()}
            </div>
            <div className='col-lg-6 flex-col text-center px-0 align-items-center mb-auto'>{taskLoggedBarGraph()}</div>
          </React.Fragment>
        )}
      </React.Fragment>
    );
  }
  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Tooltip and Add to Scorecard*/}
      <Box sx={StatsViewReportTooltipRowStyle}>
        {/* Tooltip */}
        <KpiTooltip selectedSubset='kpiTasksLogged' />
        {/* Add to Scorecard Button & Modal */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <ScorecardSelectHolder
            autofillPageName={'Farming Tasks Logged'}
            pageFilters={taskFilters}
            pageStates={{
              selectedZoneLevel: selectedZoneLevel,
            }}
          />
        </Box>
      </Box>
      {!missingTaskData || !taskLoggedData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* Filters */}
          <StatsViewReportFiltersWrapper key={`${selectedZoneLevel}Filter`}>
            {/* Zone Filter */}
            <IntelliSearchSelect
              allNoneEnabled={true}
              inputLabel={selectedZoneLevel + "s"}
              id='dashboard-tasks-zone-filter'
              name={selectedZoneLevel == 'Field' ? 'fields' : 'regions'}
              multiple={true}
              search={true}
              value={taskFilters[selectedZoneLevel == 'Field' ? 'fields' : 'regions']}
              options={filterOptions.tasks[selectedZoneLevel == 'Field' ? 'fields' : 'regions'].map((opt) => {
                return {"label": opt, "value":opt}
              })}
              onChange={(s) => {
                handleTasksFilters(s, selectedZoneLevel == 'Field' ? 'fields' : 'regions');
              }}
              placeholder={`${selectedZoneLevel} Filter`}
            />
          </StatsViewReportFiltersWrapper>
          {/* Sort By */}
          <Grid container>
            <Grid item xs={!smallScreen ? 6 : 0} >
              
            </Grid>
            <Grid item xs={!smallScreen ? 6 : 12}>
            <StatsViewReportSortByWrapper key={`${selectedZoneLevel}Sort`}>
            <IntelliSearchSelect
              id='dashboard-vehicles-tasksZonesSort-filter'
              name={selectedZoneLevel == 'Field' ? 'fieldsSort' : 'regionsSort'}
              multiple={false}
              value={taskFilters[selectedZoneLevel == 'Field' ? 'fieldsSort' : 'regionsSort']}
              options={filterOptions.tasks[selectedZoneLevel == 'Field' ? 'fieldsSort' : 'regionsSort'].map((opt) => {
                return {"label": opt.text, "value":opt.value}
              })}
              onChange={(s) => {
                handleTasksFilters(s, selectedZoneLevel == 'Field' ? 'fieldsSort' : 'regionsSort');
              }}
              placeholder='Sorting method'
            />
            {/* Zone Count Filter */}
            <IntelliSearchSelect
              id='dashboard-vehicles-tasksZonesCount-filter'
              name={selectedZoneLevel == 'Field' ? 'fieldsCount' : 'regionsCount'}
              multiple={false}
              value={taskFilters[selectedZoneLevel == 'Field' ? 'fieldsCount' : 'regionsCount']}
              options={filterOptions.tasks[selectedZoneLevel == 'Field' ? 'fieldsCount' : 'regionsCount'].map((opt) => {
                return {"label": opt.text, "value":opt.value}
              })}
              onChange={(s) => {
                handleTasksFilters(s, selectedZoneLevel == 'Field' ? 'fieldsCount' : 'regionsCount');
              }}
              placeholder={`Show  All ${selectedZoneLevel}s`}
            />
          </StatsViewReportSortByWrapper>
            </Grid>
          
          </Grid>
          
          {/* Graph and XY Axis Control */}
          <Grid container sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}} spacing={0}>
            {/* Graph */}
            <Grid item xs={12} sm={6} sx={{textAlign: 'center', alignItems: 'center'}}>
              {taskLoggedDoughnutGraph()}
            </Grid>
            <Grid item xs={12} sm={6} sx={{textAlign: 'center', alignItems: 'center'}}>
              {taskLoggedBarGraph()}
            </Grid>
            {/* X-Axis Control */}
            <Grid item xs={6}>
              {/* Empty Grid to Pad X-Axis Control */}
            </Grid>
            <Grid item xs={6} sx={{alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}>
              <FormControl sx={{minWidth: '120px', m: 1}}>
                <InputLabel>View by</InputLabel>
                <Select
                  size='small'
                  value={selectedZoneLevel}
                  label='View by'
                  onChange={(e) => {
                    setSelectedZoneLevel(e.target.value);
                  }}
                >
                  <MenuItem value={'Field'}>{'Field'}</MenuItem>
                  <MenuItem value={'Region'}>{'Region'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiTasksLogged};
