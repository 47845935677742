import React, {useState, useEffect} from 'react';
import {useSelector, useDispatch} from 'react-redux';
import {
  sendGAEvent,
  squareButton,
  machineTypeMapping,
  unitsLengthDisplayConversion,
  unitsLengthSubmitConversion,
  fetchPostAuthSafe,
  deepCopy,
} from '../../../../app/utils';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Button} from '@mui/material';
import {DateTime} from 'luxon';

import {
  updateLoading,
  updateSortMethod,
  updateDisplayedTable,
  updateShowShopviewOnlyVehicle,
  updateOnlyShowVehiclesWithoutManualEntry,
} from './vehicleInfoSlice';
import {setVehiclesBulkEditErrors} from '../../settingsSlice';

function Menu(props) {
  const dispatch = useDispatch();

  const onlyShowVehiclesWithoutManualEntry = useSelector((state) => {
    return state.vehicleinfo.onlyShowVehiclesWithoutManualEntry;
  });
  const showShopviewOnlyVehicle = useSelector((state) => {
    return state.vehicleinfo.showShopviewOnlyVehicle;
  });
  const newEquipmentEnabled = useSelector((state) => {
    return state.app.customerSettings.shopview.nonGoDeviceVehiclesEnabled;
  });
  const vehicleData = useSelector((state) => {
    return state.vehicleinfo.vehicleData;
  });
  const activeDevices = useSelector((state) => {
    return state.settings.activeDevices;
  });
  const deviceStatuses = useSelector((state) => {
    return state.vehicleinfo.deviceStatuses;
  });
  const sortMethod = useSelector((state) => {
    return state.vehicleinfo.sortMethod;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const excludedVehicles = useSelector((state) => {
    return state.app.userSettings.general.excludedVehicles;
  });
  const displayedTable = useSelector((state) => {
    return state.vehicleinfo.displayedTable;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const taskConfigDict = useSelector((state) => {
    return state.settings.taskConfigDict;
  });
  const implementSNDict = useSelector((state) => {
    return state.settings.implementSNDict;
  });
  const bulkEditVehicles = useSelector((state) => {
    return state.settings.bulkEditVehicles;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const vehiclesBulkEditErrors = useSelector((state) => {
    return state.settings.vehiclesBulkEditErrors;
  });
  
  const [bulkSubmissionState, setBulkSubmissionState] = useState('pending');
  const [unitsLength, setUnitsLength] = useState('km');

  useEffect(() => {
    // Determine length units based on customer length units system
    if (unitsLengthSystem == 'imperial') {
      setUnitsLength('mi');
    } else {
      setUnitsLength('km');
    }
  }, [unitsLengthSystem]);

  function downloadCSV() {
    const csvList = [];
    const vehicles = [];
    // Have to do this or get errors with trying to extend an object from state
    vehicleData.forEach((vehicle) => {
      const vehicleIcActive = vehicle.icActive == true;
      const manualEntryCheck = !onlyShowVehiclesWithoutManualEntry || !vehicle.lastKnownExists;

      const shopviewOnly = vehicle?.geotabDevice?.serialNumber ? vehicle?.geotabDevice?.serialNumber : '';
      const shopviewOnlyStatus = shopviewOnly == '';
      const shopviewOnlyCheck = !showShopviewOnlyVehicle || !shopviewOnly;

      let harnessType = 'Not Installed'; // Only need one cell to say this
      let powerType = '';
      let silentMode = '';
      let logAll = '';
      let zeroSpeedGpsFreeze = '';
      let curveDistError = '';
      let positionEstError = '';
      let maxCurveSpeedError = '';
      let speedEstError = '';
      let logDebug = '';
      let gpsOffDelay = '';
      if (
        vehicle.geotabDevice?.serialNumber &&
        Object.prototype.hasOwnProperty.call(activeDevices.geotab, vehicle.geotabDevice.id)
      ) {
        const device = activeDevices.geotab[vehicle.geotabDevice.id];

        // Get geotab params
        silentMode = false;
        logAll = false;
        zeroSpeedGpsFreeze = false;
        curveDistError = false;
        positionEstError = false;
        maxCurveSpeedError = false;
        speedEstError = false;
        logDebug = false;

        if (Object.prototype.hasOwnProperty.call(device, 'customParameters')) {
          for (let i = 0; i < device.customParameters.length; i++) {
            const param = device.customParameters[i];
            if (param.description.includes('Silent Mode') && param.isEnabled) {
              silentMode = true;
            }
            if (param.description.includes('Log All') && param.isEnabled) {
              logAll = true;
            }
            if (param.description.includes('Zero Speed Gps') && param.isEnabled) {
              zeroSpeedGpsFreeze = true;
            }
            if (param.description.includes('Curve Distance Error') && param.isEnabled) {
              curveDistError = true;
            }
            if (param.description.includes('Position Estimate Error') && param.isEnabled) {
              positionEstError = true;
            }
            if (param.description.includes('Max Curve Speed Error') && param.isEnabled) {
              maxCurveSpeedError = true;
            }
            if (param.description.includes('Speed Estimate Error') && param.isEnabled) {
              speedEstError = true;
            }
            if (param.description.includes('Log Debug') && param.isEnabled) {
              logDebug = true;
            }
          }
        }
        gpsOffDelay = typeof device.gpsOffDelay == 'number' ? device.gpsOffDelay / 60 : '';

        // Get device status
        const deviceStatus = deviceStatuses[vehicle.serialNumber];
        if (deviceStatus.harnessType == null) {
          harnessType = 'Loading';
        } else {
          harnessType = deviceStatus.harnessType;
        }
        if (deviceStatus.powerType == null) {
          powerType = 'Loading';
        } else {
          powerType = deviceStatus.powerType;
        }
      }

      const tempVehObj = {
        ...vehicle,
        hasManualEntry: !!vehicle.lastKnownExists,
        shopviewOnlyStatus: shopviewOnlyStatus,
        harnessType: harnessType,
        powerType: powerType,
        silentMode: silentMode,
        logAll: logAll,
        zeroSpeedGpsFreeze: zeroSpeedGpsFreeze,
        curveDistError: curveDistError,
        positionEstError: positionEstError,
        maxCurveSpeedError: maxCurveSpeedError,
        speedEstError: speedEstError,
        logDebug: logDebug,
        gpsOffDelay: gpsOffDelay,
      };
      if (
        vehicleIcActive &&
        manualEntryCheck &&
        shopviewOnlyCheck &&
        !excludedVehicles.includes(vehicle.serialNumber)
      ) {
        vehicles.push({...tempVehObj});
      }
    });

    vehicles.map((vehicle, index) => {
      const machineTypeString = machineTypeMapping[vehicle.machineType] || 'Not Assigned';

      let permTaskDisp = 'None';
      let permImpDisp = 'None';

      if (vehicle.linkedImplementSN != '') {
        if (Object.prototype.hasOwnProperty.call(implementSNDict, vehicle.linkedImplementSN)) {
          vehicle.linkedTaskId = implementSNDict[vehicle.linkedImplementSN].linkedTaskId;
          const impObj = implementSNDict[vehicle.linkedImplementSN];
          permImpDisp = impObj.name + (impObj?.archived ? ' - Archived' : '');

          if (
            Object.prototype.hasOwnProperty.call(
              taskConfigDict,
              implementSNDict[vehicle.linkedImplementSN].linkedTaskId
            )
          ) {
            const taskObj = taskConfigDict[implementSNDict[vehicle.linkedImplementSN].linkedTaskId];
            permTaskDisp = taskObj.name + (taskObj?.archived ? ' - Archived' : '');
          }
        }
      }

      if (
        permTaskDisp == 'None' &&
        vehicle.linkedTaskId != '' &&
        Object.prototype.hasOwnProperty.call(taskConfigDict, vehicle.linkedTaskId)
      ) {
        const taskObj = taskConfigDict[vehicle.linkedTaskId];
        permTaskDisp = taskObj.name + (taskObj?.archived ? ' - Archived' : '');
      }

      const geotabDeviceId = vehicle?.geotabDevice?.id != undefined ? vehicle?.geotabDevice?.id : '';
      const geotabDeviceSerial =
        vehicle?.geotabDevice?.serialNumber != undefined && vehicle?.geotabDevice?.serialNumber != '000-000-0000'
          ? vehicle?.geotabDevice?.serialNumber
          : '';

      // Try to find beWhereSerial from the geotab device comment
      let beWhereSerial = undefined;
      if (geotabDeviceId) {
        // BeWhere Serial in lower case
        const bewhereSerialRegex = /btsae[0-9]{14}/g;
        const geotabComment = activeDevices.geotab[geotabDeviceId]?.comment;
        if (geotabComment) {
          // convert comment to all lowercase to avoid missing serial due to case sensitivity
          const beWhereSerialFound = geotabComment.toLowerCase().match(bewhereSerialRegex);
          if (beWhereSerialFound) {
            // convert matched serial to uppercase for display
            beWhereSerial = beWhereSerialFound[0].toUpperCase();
          }
        }
      }

      const deviceString =
        geotabDeviceSerial != undefined && geotabDeviceSerial != '' && geotabDeviceSerial != '000-000-0000'
          ? beWhereSerial ?? geotabDeviceSerial
          : 'Not Installed';

      let row = [
        `\"${vehicle.name}\"`,
        `\"${deviceString}\"`,
        `\"${vehicle.vin}\"`,
        `\"${vehicle.make}\"`,
        `\"${vehicle.model}\"`,
        `\"${machineTypeString}\"`,
        `\"${permTaskDisp}\"`,
        `\"${permImpDisp}\"`,
        `\"${vehicle.notes}\"`,
        vehicle.engineHoursValue,
        unitsLengthDisplayConversion(vehicle.odometerValue, unitsLength),
        vehicle.shopviewOnlyStatus,
        vehicle.hasManualEntry,
      ];

      if (userSettings.role == 'ic_admin') {
        row = row.concat([
          `\"${vehicle.harnessType}\"`,
          `\"${vehicle.powerType}\"`,
          vehicle.silentMode,
          vehicle.logAll,
          vehicle.zeroSpeedGpsFreeze,
          vehicle.curveDistError,
          vehicle.positionEstError,
          vehicle.maxCurveSpeedError,
          vehicle.speedEstError,
          vehicle.logDebug,
          vehicle.gpsOffDelay,
        ]);
      }
      csvList.push([row]);
    });

    // eslint-disable-next-line max-len
    let csvHeader =
      `\"Vehicle Name\",\"Gps Device\",\"Vin\",\"Make\",\"Model\",\"Type\",\"Permanent Task\",\"Permanent Implement\",` +
      `\"Notes\",\"Engine Hours(hr)\",\"Odometer(${unitsLength})\",\"Shopview Only\",\"Has Manual Entry\",`;
    if (userSettings.role == 'ic_admin') {
      csvHeader +=
        `\"Harness Type\",\"Power Type\",\"Silent Mode\",\"Log All\",\"Zero Speed GPS Freeze\",\"Curve Distance Error\",` +
        `\"Position Estimate Error\",\"Max Curve Speed Error\",\"Speed Estimate Error\",\"Log Debug\",\"Gps Off Delay (Hours)\"`;
    }
    let csv = `${csvHeader}\n`;
    csvList.forEach(function (row) {
      csv += row.join(',');
      csv += '\n';
    });

    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.href = hiddenElement.href.replace(/#/g, '%23');
    hiddenElement.target = '_blank';
    hiddenElement.download = 'VehicleInfo.csv';
    hiddenElement.click();

    sendGAEvent('csv_download', 'vehInfo', 'vehInfo');
  }

  function vehicleTableMenu() {
    return (
      <React.Fragment>
        {userSettings.roleViewAccess['vehicleAttributesManagement'] && (
          <button
            style={displayedTable == 'attributes' ? {fontWeight: 'bolder', opacity: '1'} : {}}
            className='btn border-dark btn-light cropview-menu-text'
            disabled={displayedTable == 'attributes'}
            onClick={() => {
              // props.setFilterDefault();
              dispatch(updateDisplayedTable('attributes'));
            }}
          >
            Attributes
          </button>
        )}
        {userSettings.roleViewAccess['vehicleStatusManagement'] && (
          <button
            style={displayedTable == 'status' ? {fontWeight: 'bolder', opacity: '1'} : {}}
            className='btn border-dark btn-light cropview-menu-text'
            disabled={displayedTable == 'status'}
            onClick={() => {
              // props.setFilterDefault();
              dispatch(updateDisplayedTable('status'));
            }}
          >
            Status
          </button>
        )}
        {userSettings.roleViewAccess['vehicleFeaturesManagement'] && (
          <button
            style={displayedTable == 'features' ? {fontWeight: 'bolder', opacity: '1'} : {}}
            className='btn border-dark btn-light cropview-menu-text'
            disabled={displayedTable == 'features'}
            onClick={() => {
              // props.setFilterDefault();
              dispatch(updateDisplayedTable('features'));
            }}
          >
            Features
          </button>
        )}
      </React.Fragment>
    );
  }

  async function submitBulkEdits() {
    const validationErrors = deepCopy(vehiclesBulkEditErrors);
    const editVehiclesList = [];
    const editVehiclesOdometerList = [];
    const editVehiclesEngineHoursList = [];

    Object.keys(bulkEditVehicles).forEach((vehicleSN) => {
      const updateDoc = bulkEditVehicles[vehicleSN];
      if (Object.keys(updateDoc).length > 0) {
        const targetVehicle = vehicleData.find((vehicle) => {
          return vehicle.serialNumber == vehicleSN;
        });

        const tempVehicleUpdateDoc = {
          serialNumber: vehicleSN,
          diffDoc: {},
        };
        const bulkEditVehicleDocKeys = [
          'vin',
          'make',
          'model',
          'notes',
          'machineType',
          'linkedTaskId',
          'linkedImplementSN',
          'permanentTaskImpExpiryDate',
          'icActive',
          'cabviewActive',
          'coverageActive',
          'shopActive',
          'inspectionActive',
        ];

        for (let i = 0; i < bulkEditVehicleDocKeys.length; i++) {
          if (
            updateDoc.hasOwnProperty(bulkEditVehicleDocKeys[i]) &&
            targetVehicle[bulkEditVehicleDocKeys[i]] != updateDoc[bulkEditVehicleDocKeys[i]]
          ) {
            tempVehicleUpdateDoc['diffDoc'][bulkEditVehicleDocKeys[i]] = updateDoc[bulkEditVehicleDocKeys[i]];
          }
        }

        if (Object.keys(tempVehicleUpdateDoc['diffDoc']).length > 0) {
          editVehiclesList.push(tempVehicleUpdateDoc);
        }

        if (updateDoc.hasOwnProperty('engineHoursValue')) {
          editVehiclesEngineHoursList.push({
            vehicleSN: vehicleSN,
            engineHours: updateDoc['engineHoursValue'],
            dateTime: DateTime.now().setZone(customerSettings.general.timeZone).toISO(),
            name: targetVehicle.name,
          });
        }

        if (updateDoc.hasOwnProperty('odometerValue')) {
          if (typeof updateDoc['odometerValue'] == 'number') {
          }
          editVehiclesOdometerList.push({
            vehicleSN: vehicleSN,
            odometer: unitsLengthSubmitConversion(updateDoc['odometerValue'], unitsLength),
            dateTime: DateTime.now().setZone(customerSettings.general.timeZone).toISO(),
            name: targetVehicle.name.trim(),
          });
        }
      }
    });

    if (Object.keys(validationErrors).length == 0) {
      props.toggleBulkEditMode();
      props.resetBulkEditData();
      dispatch(updateLoading(true));
      if (editVehiclesList.length > 0) {
        // Submit all vehicle doc updates (route can take array of docs by default)
        const vehicleEditResponse = await props.postVehicleUpdates(editVehiclesList);
      }

      // TODO: Remove comment which disable engineHour and Odometer bulk update, revisit in V2
      // if (editVehiclesEngineHoursList.length > 0){
      //   // Submit Engine Hours Updates
      //   const engineHoursRecordRequests = editVehiclesEngineHoursList.map((param) => {
      //     return submitEngineHourRecord(param);
      //   });
      //   const engineHoursRecordResponses = await Promise.all(engineHoursRecordRequests);
      // }

      // if (editVehiclesOdometerList.length > 0){
      //   // Submit Odometer Updates
      //   const odometerRecordRequests = editVehiclesOdometerList.map((param) => {
      //     return submitOdoValueUpdate(param);
      //   });
      //   const odometerRecordResponses = await Promise.all(odometerRecordRequests);
      // }

      // Refresh Data, This handles Changing the Table Loading State back to false as well.
      props.refreshData();
    }
  }

  async function submitEngineHourRecord(submitParam) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submitParam),
    };
    const addEntryReq = await fetchPostAuthSafe(
      '/shopview/createEngineRecord',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const addEntryRepData = await addEntryReq.json();
    if (addEntryRepData.errorMsg) {
      navigate('/error', {state: {errorMsg: addEntryRepData.errorMsg}});
    }
    return addEntryRepData;
  }

  async function submitOdoValueUpdate(submitParam) {
    const options = {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(submitParam),
    };
    const updateOdoReq = await fetchPostAuthSafe(
      '/shopview/createOdoRecord',
      options,
      userSettings.username,
      userSettings.databaseName
    );
    const updateOdoRepData = await updateOdoReq.json();
    if (updateOdoRepData.errorMsg) {
      navigate('/error', {state: {errorMsg: updateOdoRepData.errorMsg}});
    }
    return updateOdoRepData;
  }

  // The class names with 'md' are specifically for small screen sizes (< 767 px)
  return (
    <React.Fragment>
      <div className='pt-2 pb-0'>
        <h5 style={{color: 'black'}}>Vehicles Management</h5>
        {!smallScreen ? (
          <div className='row mx-0 my-1'>
            {((newEquipmentEnabled && userSettings.roleAccess['vehicleAttributesManagement']) ||
              userSettings.role == 'ic_admin') && (
              <div className='px-0 d-inline-flex'>
                <Button
                  sx={squareButton}
                  variant='ic-button'
                  color='success'
                  data-toggle='modal'
                  data-target='#shopview-new-equipment-modal'
                  title='Add vehicle'
                >
                  <FontAwesomeIcon icon='fas fa-plus' />
                </Button>
                <Button
                  sx={squareButton}
                  variant='ic-button'
                  color='success'
                  title='Dropzone'
                  className='ml-1'
                  onClick={() => {
                    props.handleBulkUploadModalOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon='fas fa-upload' />
                </Button>
              </div>
            )}
            <div className='btn-group col-8 col-md-auto px-0 px-md-1'>{vehicleTableMenu()}</div>
            <div className='btn-group col-4 col-md-auto px-0 px-md-1'>
              <button
                className='btn border-dark btn-light cropview-menu-text'
                title='Vehicles without manual entry'
                onClick={() => {
                  if (onlyShowVehiclesWithoutManualEntry == false) {
                    dispatch(updateOnlyShowVehiclesWithoutManualEntry(true));
                  } else {
                    dispatch(updateOnlyShowVehiclesWithoutManualEntry(false));
                  }
                }}
              >
                {onlyShowVehiclesWithoutManualEntry ? (
                  'All'
                ) : (
                  <FontAwesomeIcon icon='fa-solid fa-triangle-exclamation' />
                )}
              </button>
              <button
                className='btn border-dark btn-light cropview-menu-text'
                title='ShopView only vehicles'
                onClick={() => {
                  if (showShopviewOnlyVehicle == false) {
                    dispatch(updateShowShopviewOnlyVehicle(true));
                  } else {
                    dispatch(updateShowShopviewOnlyVehicle(false));
                  }
                }}
              >
                {showShopviewOnlyVehicle ? 'All' : <FontAwesomeIcon icon='fa-solid fa-wrench' />}
              </button>
            </div>
            <div className='col-2 col-md-auto px-0 px-md-1 dropdown' style={{maxWidth: '12.5%'}}>
              <button
                className='btn border-dark btn-light col-12 cropview-menu-text'
                data-toggle='dropdown'
                title='Sort'
              >
                <FontAwesomeIcon icon='fas fa-sort-amount-down' />
              </button>
              <div className='dropdown-menu border-dark' style={{minWidth: '0'}}>
                <div className='dropdown-header cropview-menu-text px-3'>Sort By</div>
                <button
                  className='dropdown-item cropview-menu-text px-3'
                  onClick={() => {
                    dispatch(updateSortMethod('vehiclename'));
                  }}
                >
                  Vehicle Name {sortMethod == 'vehiclename' && <FontAwesomeIcon icon='fas fa-check' />}
                </button>
                {displayedTable == 'status' && (
                  <React.Fragment>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('enginehours'));
                      }}
                    >
                      Engine Hours {sortMethod == 'enginehours' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('odometer'));
                      }}
                    >
                      Odometer {sortMethod == 'odometer' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('lastCommunicationUp'));
                      }}
                    >
                      Last Communication <FontAwesomeIcon icon='fas fa-arrow-up' />
                      &nbsp;
                      {sortMethod == 'lastCommunicationUp' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('lastCommunicationDown'));
                      }}
                    >
                      Last Communication <FontAwesomeIcon icon='fas fa-arrow-down' />
                      &nbsp;
                      {sortMethod == 'lastCommunicationDown' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                  </React.Fragment>
                )}
                {displayedTable == 'attributes' && (
                  <React.Fragment>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('make'));
                      }}
                    >
                      Make {sortMethod == 'make' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('model'));
                      }}
                    >
                      Model {sortMethod == 'model' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('width'));
                      }}
                    >
                      Width {sortMethod == 'width' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('machineType'));
                      }}
                    >
                      Type {sortMethod == 'machineType' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('linkedTaskId'));
                      }}
                    >
                      Permanent Task {sortMethod == 'linkedTaskId' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('linkedImplementSN'));
                      }}
                    >
                      Permanent Implement {sortMethod == 'linkedImplementSN' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('notes'));
                      }}
                    >
                      Notes {sortMethod == 'notes' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                  </React.Fragment>
                )}

                {displayedTable == 'features' && (
                  <React.Fragment>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('icActive'));
                      }}
                    >
                      IC {sortMethod == 'icActive' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('cabviewActive'));
                      }}
                    >
                      CabView {sortMethod == 'cabviewActive' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('coverageActive'));
                      }}
                    >
                      Coverage {sortMethod == 'coverageActive' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>

                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('shopActive'));
                      }}
                    >
                      ShopView {sortMethod == 'shopActive' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>

                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('inspectionActive'));
                      }}
                    >
                      Inspection {sortMethod == 'inspectionActive' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className='col-2 col-md-auto px-0 px-md-1' style={{maxWidth: '12.5%'}}>
              <button
                type='button'
                className='btn border-dark btn-light col-12 cropview-menu-text'
                title='Download CSV'
                onClick={downloadCSV}
              >
                <FontAwesomeIcon icon='fas fa-download' />
              </button>
            </div>
            {(userSettings.roleAccess['vehicleAttributesManagement'] ||
              userSettings.roleAccess['vehicleFeaturesManagement']) && (
              <Button
                sx={squareButton}
                variant='ic-button'
                color={props.bulkEditMode ? 'danger' : 'secondary'}
                onClick={props.toggleBulkEditMode}
                title='Bulk Edit Mode'
              >
                {props.bulkEditMode ? (
                  <FontAwesomeIcon icon='fas fa-xmark-circle' />
                ) : (
                  <FontAwesomeIcon icon='fas fa-edit' />
                )}
              </Button>
            )}
            {props.bulkEditMode && (
              <React.Fragment>
                <Button
                  sx={squareButton}
                  variant='ic-button'
                  color='primary'
                  title='Bulk Edit Submit'
                  className='ml-1'
                  onClick={() => {
                    submitBulkEdits();
                  }}
                >
                  <FontAwesomeIcon icon='fas fa-floppy-disk' />
                </Button>
                <Button
                  sx={squareButton}
                  variant='ic-button'
                  color='secondary'
                  title='Bulk Edit Reset'
                  className='ml-1'
                  onClick={() => {
                    props.resetBulkEditData();
                    dispatch(setVehiclesBulkEditErrors({}));
                  }}
                >
                  <FontAwesomeIcon icon='fas fa-trash-can' />
                </Button>
              </React.Fragment>
            )}
          </div>
        ) : (
          <div className='row mx-0 my-1 justify-content-center'>
            {((newEquipmentEnabled && userSettings.roleAccess['vehicleAttributesManagement']) ||
              userSettings.role == 'ic_admin') && (
              <div className='btn-group col-2 col-md-auto px-0 px-md-1'>
                <Button
                  sx={squareButton}
                  variant='ic-button'
                  color='success'
                  data-toggle='modal'
                  data-target='#shopview-new-equipment-modal'
                  title='Add vehicle'
                >
                  <FontAwesomeIcon icon='fas fa-plus' />
                </Button>
                <Button
                  sx={squareButton}
                  variant='ic-button'
                  color='success'
                  title='Bulk Upload'
                  className='ml-1'
                  onClick={() => {
                    props.handleBulkUploadModalOpen(true);
                  }}
                >
                  <FontAwesomeIcon icon='fas fa-upload' />
                </Button>
              </div>
            )}

            <div className='btn-group col-5 col-md-auto px-0 px-md-1'>
              <button
                className='btn border-dark btn-light cropview-menu-text'
                title='Vehicles without manual entry'
                onClick={() => {
                  if (onlyShowVehiclesWithoutManualEntry == false) {
                    dispatch(updateOnlyShowVehiclesWithoutManualEntry(true));
                  } else {
                    dispatch(updateOnlyShowVehiclesWithoutManualEntry(false));
                  }
                }}
              >
                {onlyShowVehiclesWithoutManualEntry ? (
                  'All'
                ) : (
                  <FontAwesomeIcon icon='fa-solid fa-triangle-exclamation' />
                )}
              </button>
              <button
                className='btn border-dark btn-light cropview-menu-text'
                title='ShopView only vehicles'
                onClick={() => {
                  if (showShopviewOnlyVehicle == false) {
                    dispatch(updateShowShopviewOnlyVehicle(true));
                  } else {
                    dispatch(updateShowShopviewOnlyVehicle(false));
                  }
                }}
              >
                {showShopviewOnlyVehicle ? 'All' : <FontAwesomeIcon icon='fa-solid fa-wrench' />}
              </button>
            </div>
            <div className='btn-group col-3 col-md-auto px-0 px-md-1'>
              <button
                className='btn border-dark btn-light col-12 cropview-menu-text'
                data-toggle='dropdown'
                title='Sort'
              >
                <FontAwesomeIcon icon='fas fa-sort-amount-down' />
              </button>
              <div className='dropdown-menu border-dark' style={{minWidth: '0'}}>
                <div className='dropdown-header cropview-menu-text px-3'>Sort By</div>
                <button
                  className='dropdown-item cropview-menu-text px-3'
                  onClick={() => {
                    dispatch(updateSortMethod('vehiclename'));
                  }}
                >
                  Vehicle Name {sortMethod == 'vehiclename' && <FontAwesomeIcon icon='fas fa-check' />}
                </button>
                {displayedTable == 'status' && (
                  <React.Fragment>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('enginehours'));
                      }}
                    >
                      Engine Hours {sortMethod == 'enginehours' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('odometer'));
                      }}
                    >
                      Odometer {sortMethod == 'odometer' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('lastCommunicationUp'));
                      }}
                    >
                      Last Communication <FontAwesomeIcon icon='fas fa-arrow-up' />
                      &nbsp;
                      {sortMethod == 'lastCommunicationUp' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('lastCommunicationDown'));
                      }}
                    >
                      Last Communication <FontAwesomeIcon icon='fas fa-arrow-down' />
                      &nbsp;
                      {sortMethod == 'lastCommunicationDown' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                  </React.Fragment>
                )}
                {displayedTable == 'attributes' && (
                  <React.Fragment>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('make'));
                      }}
                    >
                      Make {sortMethod == 'make' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('model'));
                      }}
                    >
                      Model {sortMethod == 'model' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('width'));
                      }}
                    >
                      Width {sortMethod == 'width' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('machineType'));
                      }}
                    >
                      Type {sortMethod == 'machineType' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('linkedTaskId'));
                      }}
                    >
                      Permanent Task {sortMethod == 'linkedTaskId' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>

                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('linkedImplementSN'));
                      }}
                    >
                      Permanent Implement {sortMethod == 'linkedImplementSN' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('notes'));
                      }}
                    >
                      Notes {sortMethod == 'notes' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                  </React.Fragment>
                )}

                {displayedTable == 'features' && (
                  <React.Fragment>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('icActive'));
                      }}
                    >
                      IC {sortMethod == 'icActive' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('cabviewActive'));
                      }}
                    >
                      CabView {sortMethod == 'cabviewActive' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('coverageActive'));
                      }}
                    >
                      Coverage {sortMethod == 'coverageActive' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>

                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('shopActive'));
                      }}
                    >
                      ShopView {sortMethod == 'shopActive' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>

                    <button
                      className='dropdown-item cropview-menu-text px-3'
                      onClick={() => {
                        dispatch(updateSortMethod('inspectionActive'));
                      }}
                    >
                      Inspection {sortMethod == 'inspectionActive' && <FontAwesomeIcon icon='fas fa-check' />}
                    </button>
                  </React.Fragment>
                )}
              </div>
            </div>
            <div className='btn-group col-2 col-md-auto px-0 px-md-1'>
              <button
                type='button'
                className='btn border-dark btn-light col-12 cropview-menu-text'
                title='Download CSV'
                onClick={downloadCSV}
              >
                <FontAwesomeIcon icon='fas fa-download' />
              </button>
            </div>
            <div className='btn-group col-12 col-md-auto px-0 px-md-1'>{vehicleTableMenu()}</div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export {Menu};
