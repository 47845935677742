import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties} from '../../../app/utils';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {genIdlePercentByMachine} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportTooltipRowStyle,
} from '../dashboardUtils';
import {Select, MenuItem, CircularProgress, FormControl, InputLabel, Grid, Box} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

function KpiIdling(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const installTypes = useSelector((state) => {
    return state.dashboard.installTypes;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const activeDevices = useSelector((state) => {
    return state.dashboard.activeDevices;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const [displayedAxis, setDisplayedAxis] = useState('Idle %');
  const [idleData, setIdleData] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [idleTarget, setIdleTarget] = useState(null);
  const [idleFilters, setIdleFilters] = useState({
    idleVehicleSNs: [],
    idleVehicleType: [],
    idleVehicleCount: '25',
    idleVehicleSort: 'Desc',
  });

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [operationsData, vehicleSNDict, idleFilters, activeDevices, displayedAxis]);

  useEffect(() => {
    if (props?.pageFilters) {
      setIdleFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageFilters]);

  function generateData() {
    // Set target
    let idleTargetTemp = customerSettings?.targets?.totalIdlePercent;

    // If we only have 1 type selected display that vehicles target
    if (idleFilters.idleVehicleType.length == 1) {
      idleTargetTemp =
        customerSettings.targets.typeIdleTimePercent[idleFilters.idleVehicleType[0]] != 0
          ? customerSettings.targets.typeIdleTimePercent[idleFilters.idleVehicleType[0]]
          : undefined;
    }
    // If more than 1 type is selected don't display any target
    else if (idleFilters.idleVehicleType.length > 1) {
      idleTargetTemp = undefined;
    }
    // If there is a specific vehicle selected show that vehicle's target
    if (idleFilters.idleVehicleSNs.length == 1) {
      idleTargetTemp =
        typeof vehicleSNDict[idleFilters.idleVehicleSNs[0]].idleTimeTarget != undefined &&
        vehicleSNDict[idleFilters.idleVehicleSNs[0]].idleTimeTarget != 0
          ? vehicleSNDict[idleFilters.idleVehicleSNs[0]].idleTimeTarget
          : undefined;
    }
    // If there is more than 1 specific vehicle selected and it isn't just 1 singular type we won't display a target
    // But if it's just 1 singular type then we would be displaying the target for that type
    else if (idleFilters.idleVehicleSNs.length > 1 && idleFilters.idleVehicleType.length != 1) {
      idleTargetTemp = undefined;
    }
    setIdleTarget(idleTargetTemp);
    if (props.hasOwnProperty('pageStates') && props.pageStates != undefined) {
      if (props.pageStates.hasOwnProperty('pageTargets')) {
        setIdleTarget(props.pageStates.pageTargets);
      }
      if (props.pageStates.hasOwnProperty('displayedAxis')) {
        setDisplayedAxis(props.pageStates.displayedAxis);
      }
    }

    if (displayedAxis != 'Idle %') {
      setIdleTarget(null);
    }

    // Generate data
    let installTypesInput = installTypes;
    if (
      typeof customerSettings.dashboard.kpiIdleInstallStatusWarningDisplay !== 'undefined' &&
      customerSettings.dashboard.kpiIdleInstallStatusWarningDisplay === false
    ) {
      installTypesInput = {};
    }

    const idlePercentByMachineTemp = genIdlePercentByMachine(
      operationsData.vehiclesObject,
      displayedAxis,
      idleFilters,
      vehicleSNDict,
      installTypesInput,
      activeDevices,
      customerSettings.dashboard.kpiIdleHoverDetailsEnabled
    );

    if (Object.keys(operationsData.vehiclesObject).length > 0) {
      // Set data
      setIdleData(idlePercentByMachineTemp);

      // Set datasets
      const datasetsIncluded = [];
      idlePercentByMachineTemp.datasets.forEach((dataset) => {
        if (displayedAxis == dataset.label || ['Missing Idle Data', 'Target'].includes(dataset.label)) {
          datasetsIncluded.push(dataset);
        }
      });
      setDatasets(datasetsIncluded);
    } else {
      setIdleData(null);
      setDatasets(null);
    }
  }

  function handleVehiclesFilters(value, selectName) {
    setIdleFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function handleVehiclesFiltersOld(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    const value = select.multiple ? selected : selected[0];
    const name = select.name;
    setIdleFilters((values) => {
      return {...values, [name]: value};
    });
  }

  function idlingGraph() {
    return (
      <div style={{height: '60vh', minHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={{
            labels: idleData.labels,
            datasets: datasets,
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              annotation: {
                annotations: {
                  line1: {
                    type: 'line',
                    scaleID: 'y',
                    value: idleTarget,
                    endValue: idleTarget,
                    borderColor: 'rgb(255, 99, 132)',
                    borderWidth: 2,
                    display: typeof idleTarget != 'undefined' && idleTarget != 0,
                  },
                },
              },
              title: {
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
                text: `Operational Efficiency - ${displayedAxis} by Machine`,
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  filter: function (legendItem, data) {
                    return legendItem.text != 'Target' || idleTarget;
                  },
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              y: {
                title: {
                  text: displayedAxis,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
              TargetAxis: {
                title: {
                  text: '',
                  display: false,
                },
                display: false,
              },
              x: {
                ticks: {
                  autoSkip: idleData.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 90,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: 'Machine',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
          plugins={[
            {
              id: 'customPlugin',
              beforeDraw: function (chart) {
                chart.config.data.datasets.forEach((dataset, i) => {
                  if (dataset.legendBackgroundColor) {
                    chart.legend.legendItems[i].fillStyle = dataset.legendBackgroundColor;
                    chart.legend.legendItems[i].strokeStyle = dataset.legendBorderColor
                      ? dataset.legendBorderColor
                      : dataset.legendBackgroundColor;
                  }
                });
              },
            },
          ]}
        />
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiIdling' />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!idleData ? <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div> : idlingGraph()}
        </div>
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Tooltip and Add to Scorecard*/}
      <Box sx={StatsViewReportTooltipRowStyle}>
        {/* Tooltip */}
        <KpiTooltip selectedSubset='kpiIdling' />
        {/* Add to Scorecard Button & Modal */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <ScorecardSelectHolder
            autofillPageName={'Machine Idling'}
            pageFilters={idleFilters}
            pageStates={{
              pageTargets: idleTarget,
              displayedAxis: displayedAxis,
            }}
          />
        </Box>
      </Box>

      {!idleData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* Filters */}
            <StatsViewReportFiltersWrapper key={`statsviewReportIdleFilter`}>
              {/* Vehicle Name Filter */}
              <IntelliSearchSelect
                inputLabel={"Vehicles"}
                allNoneEnabled
                id='dashboard-vehicles-efficiencyVehicleSNs-filter'
                name='idleVehicleSNs'
                multiple={true}
                search={true}
                value={idleFilters.idleVehicleSNs}
                options={filterOptions.vehicles.vehicleSNs.map((opt) => {
                  return {"label": opt.text, "value":opt.value}
                })}
                onChange={(s) => {
                  handleVehiclesFilters(s, "idleVehicleSNs");
                }}
                placeholder='Filter for Vehicles'
              />
              {/* Vehicle Type Filter */}
              <IntelliSearchSelect
                allNoneEnabled
                inputLabel={"Vehicle Type"}
                id='dashboard-vehicles-efficiencyVehicleType-filter'
                name='idleVehicleType'
                multiple={true}
                search={true}
                value={idleFilters.idleVehicleType}
                options={filterOptions.vehicles.vehicleType.map((opt) => {
                  return {"label": opt.text, "value":opt.value}
                })}
                onChange={(s) => {
                  handleVehiclesFilters(s, "idleVehicleType");
                }}
                placeholder='Filter for Vehicle Type'
              />
            </StatsViewReportFiltersWrapper>
            {/* Sort By */}
            <Grid container>
            <Grid item xs={!smallScreen ? 6 : 0} >
              
              </Grid>
              <Grid item xs={!smallScreen ? 6 : 12}>
              <StatsViewReportSortByWrapper key={`statsviewReportIdleSort`}>
              <IntelliSearchSelect
                id='dashboard-vehicles-efficiencyVehicleSort-filter'
                name='idleVehicleSort'
                multiple={false}
                value={idleFilters.idleVehicleSort}
                options={filterOptions.vehicles.efficiencyVehicleSort.map((opt) => {return {"label": opt.text, "value":opt.value}})}
                onChange={(s) => {
                  handleVehiclesFilters(s,'idleVehicleSort');
                }}
                placeholder='Sorting method'
              />
              {/* Vehicle Count Filter */}
              <IntelliSearchSelect
                id='dashboard-vehicles-efficiencyVehicleCount-filter'
                name='idleVehicleCount'
                multiple={false}
                value={idleFilters.idleVehicleCount}
                options={filterOptions.vehicles.vehicleCount.map((opt) => {return {"label": opt.text, "value":opt.value}})}
                onChange={(s) => {
                  handleVehiclesFilters(s,'idleVehicleCount');
                }}
                placeholder={`Show  All Vehicles`}
              />
            </StatsViewReportSortByWrapper>
            </Grid>
          </Grid>
          
          {/* Graph and XY Axis Control */}
          <Grid container sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}} spacing={0}>
            {/* Y-Axis Controls */}
            <Grid
              item
              xs={2}
              lg={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  '.MuiInputBase-input': {
                    padding: '5px',
                  },
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                  transformOrigin: 'center',
                  transform: 'rotate(270deg)',
                  margin: 'auto',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    boxShadow: '-5px -5px 10px 0px #aaaaaa',
                    borderRadius: '4px',
                  }}
                >
                  <FormControl sx={{m: 1}}>
                    <InputLabel sx={{maxWidth:'none'}}>Measure by</InputLabel>
                    <Select
                      sx={{padding:'3px'}}
                      size='small'
                      label='Measure by'
                      value={displayedAxis}
                      onChange={(e) => {
                        setDisplayedAxis(e.target.value);
                      }}
                    >
                      <MenuItem value={'Idle %'}>{'Idle %'}</MenuItem>
                      <MenuItem value={'Idle Time (hr)'}>{'Idle Time (hr)'}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            {/* Graph */}
            <Grid item xs={10} lg={9} sx={{textAlign: 'center'}}>
              {idlingGraph()}
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiIdling};
