import React, {useState, useEffect, useRef} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {
  customChartProperties, 
  unitsLengthDisplayConversion, 
  deepCopy, 
  isNumeric,
  capitalizeFirstLetter,
  unitsAreaConversion,
} from '../../../app/utils';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {genEfficiencyData} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportTooltipRowStyle,
} from '../dashboardUtils';
import {
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Grid,
  Tooltip,
  Box,
  OutlinedInput,
  InputAdornment,
  Divider,
} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';


ChartJS.register(annotationPlugin);

function KpiEfficiency(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const taskConfigs = useSelector((state) => {
    return state.dashboard.taskConfigs;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const unitsAreaSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsArea;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });

  const [toolTipOpen, setTooltipOpen] = useState(false);
  const [selectedInnerSubset, setSelectedInnerSubset] = useState('Total');
  const [selectedZoneLevel, setSelectedZoneLevel] = useState('Region');
  const [targetsObject, setTargetsObject] = useState({});
  const [efficiencyData, setEfficiencyData] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [displayedAxis, setDisplayedAxis] = useState('Ac/Hr');
  const [distanceUnits, setDistanceUnits] = useState('km');
  const [speedUnits, setSpeedUnits] = useState('kph');
  const [scaleId, setScaleId] = useState('AcHr');
  const [unitsArea, setUnitsArea] = useState('ha');
  const [unitsAreaRate, setUnitsAreaRate] = useState('Ha/Hr');
  const [hourDollarRate, setHourDollarRate] = useState('');
  const [acreDollarRate, setAcreDollarRate] = useState('');
  const [innerSubsetOptions, setInnerSubsetOptions] = useState([]);
  const [efficiencyFilters, setEfficiencyFilters] = useState({
    efficiencyZoneNames: {
      'Block': [],
      'Field': [],
      'Region': [],
    },
    efficiencyZoneSort: 'Alphabetical',
    efficiencyZoneCount: '10',
    efficiencyZones: [],
    efficiencyRowSpacing: [],
  });
  const [zoneFilterOptions, setZoneFilterOptions] = useState([]);

  const additionalInputRef = useRef();

  useEffect(() => {
    if (selectedZoneLevel == 'Block' && operationsData != undefined && operationsData.hasOwnProperty('blocksObject')) {
      const efficiencyBlocks = Object.keys(deepCopy(operationsData.blocksObject));
      efficiencyBlocks.sort();
      setZoneFilterOptions(efficiencyBlocks);
    }
    if (selectedZoneLevel == 'Field' && operationsData != undefined && operationsData.hasOwnProperty('fieldsObject')) {
      const efficiencyFields = Object.keys(deepCopy(operationsData.fieldsObject));
      efficiencyFields.sort();
      setZoneFilterOptions(efficiencyFields);
    }
    if (
      selectedZoneLevel == 'Region' &&
      operationsData != undefined &&
      operationsData.hasOwnProperty('regionsObject')
    ) {
      const efficiencyRegions = Object.keys(deepCopy(operationsData.regionsObject));
      efficiencyRegions.sort();
      setZoneFilterOptions(efficiencyRegions);
    }
  }, [selectedZoneLevel, operationsData]);

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [
    operationsData,
    taskConfigs,
    selectedZoneLevel,
    selectedInnerSubset,
    efficiencyFilters,
    displayedAxis,
    acreDollarRate,
    hourDollarRate,
  ]);

  useEffect(() => {
    if (displayedAxis == 'Total $' && !acreDollarRate && !hourDollarRate) {
      additionalInputRef.current.scrollIntoView({behavior: 'smooth'});
    }
  }, [displayedAxis]);

  useEffect(() => {
    let distUnits = 'km';
    if (unitsLengthSystem == 'imperial') {
      distUnits = 'mi';
    }
    setDistanceUnits(distUnits);

    let spdUnits = 'kph';
    if (unitsLengthSystem == 'imperial') {
      spdUnits = 'mph';
    }
    setSpeedUnits(spdUnits);
  }, [unitsLengthSystem]);

  useEffect(() => {
    // Determine area units based on customer area units system
    setUnitsArea(unitsAreaSystem == 'acre' ? 'ac' : 'ha');
  }, [unitsAreaSystem]);

  useEffect(() => {
    if (props.pageStates?.selectedInnerSubset) {
      setSelectedInnerSubset(props.pageStates?.selectedInnerSubset);
    } else {
      setSelectedInnerSubset('Total');
    }
    if (props.pageStates?.selectedZoneLevel) {
      setSelectedZoneLevel(props.pageStates?.selectedZoneLevel);
    } else {
      setSelectedZoneLevel('Region');
    }
    if (props.pageStates?.displayedAxis) {
      setDisplayedAxis(props.pageStates?.displayedAxis);
    } else {
      setDisplayedAxis('Ac/Hr');
    }
    if (props.pageStates?.scaleId) {
      setScaleId(props.pageStates?.scaleId);
    } else {
      setScaleId('AcHr');
    }
    if (props.pageStates?.hourDollarRate) {
      setHourDollarRate(props.pageStates?.hourDollarRate);
    } else {
      setHourDollarRate('');
    }
    if (props.pageStates?.acreDollarRate) {
      const dollarPerAreaConverted = `${unitsAreaConversion(parseFloat(props.pageStates?.acreDollarRate), 'ac', unitsArea)}`;
      setAcreDollarRate(parseFloat(dollarPerAreaConverted));
    } else {
      setAcreDollarRate('');
    }

    if (props?.pageFilters) {
      setEfficiencyFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageStates, props.pageFilters, unitsArea]);

  function handleHourDollarRateChange(e) {
    if (isNumeric(e.target.value) || e.target.value == '') {
      setAcreDollarRate('');
      setHourDollarRate(e.target.value);
      setDisplayedAxis('Total $');
    }
    if (e.target.value == '' && acreDollarRate == '') {
      setDisplayedAxis('Ac/Hr');
    }
  }
  function handleAcreDollarRateChange(e) {
    if (isNumeric(e.target.value) || e.target.value == '') {
      setHourDollarRate('');
      setAcreDollarRate(e.target.value);
      setDisplayedAxis('Total $');
    }
    if (e.target.value == '' && hourDollarRate == '') {
      setDisplayedAxis('Ac/Hr');
    }
  }

  function generateData() {
    // Get targets
    const targetsObject = {};
    Object.keys(taskConfigs).forEach((taskId) => {
      if (taskConfigs[taskId].hasOwnProperty('acPerHrTarget') || taskConfigs[taskId].hasOwnProperty('avgSpeedTarget')) {
        if (!targetsObject.hasOwnProperty(taskId)) {
          targetsObject[taskId] = {};
        }

        // Calculate targets in hectares & acres to have both units available
        const hectaresPerHr = unitsAreaConversion(taskConfigs[taskId].acPerHrTarget, unitsArea, 'ac');
        targetsObject[taskId]['Ac/Hr'] = taskConfigs[taskId].acPerHrTarget;
        targetsObject[taskId]['Hr/Ac'] = taskConfigs[taskId].acPerHrTarget != 0 && 1 / taskConfigs[taskId].acPerHrTarget;
        targetsObject[taskId]['Ha/Hr'] = hectaresPerHr;
        targetsObject[taskId]['Hr/Ha'] = hectaresPerHr != 0 && 1 / hectaresPerHr;
        targetsObject[taskId]['Speed'] = unitsLengthDisplayConversion(taskConfigs[taskId].avgSpeedTarget * 100, distanceUnits) / 100;
      }
    });
    setTargetsObject(targetsObject);
    if (
      props.hasOwnProperty('pageStates') &&
      props.pageStates != undefined &&
      props.pageStates.hasOwnProperty('pageTargets')
    ) {
      setTargetsObject(props.pageStates.pageTargets);
    }

    const efficiencyTasksObject = deepCopy(operationsData.tasksObject);
    const efficiencyBlocksObject = deepCopy(operationsData.blocksObject);
    const efficiencyFieldsObject = deepCopy(operationsData.fieldsObject);
    const efficiencyRegionsObject = deepCopy(operationsData.regionsObject);

    Object.keys(efficiencyTasksObject).forEach((task) => {
      Object.keys(efficiencyTasksObject[task]).forEach((zoneType) => {
        Object.keys(efficiencyTasksObject[task][zoneType]).forEach((zone) => {
          // Zone in filter or the filters for this zonetype are length 0
          let zoneRowSpacingInFilter = true;

          if (zoneType == 'Block' && efficiencyFilters.efficiencyRowSpacing.length > 0) {
            const blockRowSpacing = efficiencyBlocksObject[zone].row_spacing_meters;
            const blockRowSpacingNumeric = isNumeric(blockRowSpacing) ? Math.round(blockRowSpacing * 100) / 100 : 0;
            const filteredRowSpacing = efficiencyFilters.efficiencyRowSpacing.map((rowSpacing) => {
              return parseFloat(rowSpacing);
            });
            zoneRowSpacingInFilter = filteredRowSpacing.includes(blockRowSpacingNumeric);
          }

          const zoneNameInFilter =
            efficiencyFilters.efficiencyZoneNames[zoneType].includes(zone) ||
            efficiencyFilters.efficiencyZoneNames[zoneType].length == 0;

          const zoneInFilter = zoneRowSpacingInFilter && zoneNameInFilter;

          if (efficiencyTasksObject[task][zoneType][zone]['acreage'] == 0 || !zoneInFilter) {
            delete efficiencyTasksObject[task][zoneType][zone];
          }
        });
      });
    });

    const filteredRowSpacing = efficiencyFilters.efficiencyRowSpacing.map((rowSpacing) => {
      return parseFloat(rowSpacing);
    });
    Object.keys(efficiencyBlocksObject).forEach((zone) => {
      const zoneNameInFilter =
        efficiencyFilters.efficiencyZoneNames['Block'].includes(zone) ||
        efficiencyFilters.efficiencyZoneNames['Block'].length == 0;

      let zoneRowSpacingInFilter = true;

      if (efficiencyFilters.efficiencyRowSpacing.length > 0) {
        const blockRowSpacing = efficiencyBlocksObject[zone].row_spacing_meters;
        const blockRowSpacingNumeric = isNumeric(blockRowSpacing) ? Math.round(blockRowSpacing * 100) / 100 : 0;
        zoneRowSpacingInFilter = filteredRowSpacing.includes(blockRowSpacingNumeric);
      }

      const zoneInFilter = zoneNameInFilter && zoneRowSpacingInFilter;
      if (efficiencyBlocksObject[zone]['acreage'] == 0 || !zoneInFilter) {
        delete efficiencyBlocksObject[zone];
      }
    });
    Object.keys(efficiencyFieldsObject).forEach((zone) => {
      const zoneInFilter =
        efficiencyFilters.efficiencyZoneNames['Field'].includes(zone) ||
        efficiencyFilters.efficiencyZoneNames['Field'].length == 0;
      if (efficiencyFieldsObject[zone]['acreage'] == 0 || !zoneInFilter) {
        delete efficiencyFieldsObject[zone];
      }
    });
    Object.keys(efficiencyRegionsObject).forEach((zone) => {
      const zoneInFilter =
        efficiencyFilters.efficiencyZoneNames['Region'].includes(zone) ||
        efficiencyFilters.efficiencyZoneNames['Region'].length == 0;
      if (efficiencyRegionsObject[zone]['acreage'] == 0 || !zoneInFilter) {
        delete efficiencyRegionsObject[zone];
      }
    });

    // TODO
    const combined = deepCopy(selectedInnerSubset);
    const combinedObj = {};
    if (combined.constructor === Array && combined.length > 1) {
      combined.forEach((id) => {
        if (efficiencyTasksObject.hasOwnProperty(id)) {
          const curItem = deepCopy(efficiencyTasksObject[id]);
          Object.keys(curItem).forEach((zoneType) => {
            if (!combinedObj.hasOwnProperty(zoneType)) {
              combinedObj[zoneType] = {};
            }
            Object.keys(curItem[zoneType]).forEach((zone) => {
              if (!combinedObj[zoneType].hasOwnProperty(zone)) {
                combinedObj[zoneType][zone] = deepCopy(curItem[zoneType][zone]);
              } else {
                Object.keys(curItem[zoneType][zone]).forEach((key) => {
                  combinedObj[zoneType][zone][key] += deepCopy(curItem[zoneType][zone][key]);
                });
              }
              combinedObj[zoneType][zone]['acPerHr'] =
                combinedObj[zoneType][zone]['acreage'] / combinedObj[zoneType][zone]['duration'];
              combinedObj[zoneType][zone]['avgSpeed'] =
                combinedObj[zoneType][zone]['distance'] / combinedObj[zoneType][zone]['duration'];
            });
          });
        }
      });
      if (Object.keys(combinedObj).length > 0) {
        efficiencyTasksObject['multiSelectedCombined'] = combinedObj;
      }
    }
    //
    // Generate data

    const efficiencyDataTemp = genEfficiencyData(
      efficiencyTasksObject,
      efficiencyBlocksObject,
      efficiencyFieldsObject,
      efficiencyRegionsObject,
      efficiencyFilters,
      displayedAxis,
      unitsLengthSystem,
      unitsAreaSystem,
      customerSettings.dashboard.kpiEfficiencyHoverDetailsEnabled,
      hourDollarRate,
      acreDollarRate,
      taskConfigs
    );
    if (
      selectedInnerSubset.constructor === Array &&
      selectedInnerSubset.length > 1 &&
      efficiencyDataTemp[selectedZoneLevel].hasOwnProperty('multiSelectedCombined')
    ) {
      // Set data
      setEfficiencyData(efficiencyDataTemp[selectedZoneLevel]['multiSelectedCombined']);

      // Set datasets
      const datasetsIncluded = [];
      efficiencyDataTemp[selectedZoneLevel]['multiSelectedCombined'].datasets.forEach((dataset) => {
        if (
          displayedAxis == dataset.displayedAxis ||
          (dataset.displayedAxis == 'Target' &&
            targetsObject.hasOwnProperty('multiSelectedCombined') &&
            displayedAxis != 'Total $' &&
            targetsObject['multiSelectedCombined'][[displayedAxis]] != 0)
        ) {
          datasetsIncluded.push(dataset);
        }
      });

      setDatasets(datasetsIncluded);
    } else if (
      Object.prototype.hasOwnProperty.call(efficiencyDataTemp, selectedZoneLevel) &&
      Object.prototype.hasOwnProperty.call(efficiencyDataTemp[selectedZoneLevel], selectedInnerSubset)
    ) {
      // Set data
      setEfficiencyData(efficiencyDataTemp[selectedZoneLevel][selectedInnerSubset]);

      // Set datasets
      const datasetsIncluded = [];
      efficiencyDataTemp[selectedZoneLevel][selectedInnerSubset].datasets.forEach((dataset) => {
        if (
          displayedAxis == dataset.displayedAxis ||
          (dataset.displayedAxis == 'Target' &&
            targetsObject.hasOwnProperty(selectedInnerSubset) &&
            displayedAxis != 'Total $' &&
            targetsObject[selectedInnerSubset][[displayedAxis]] != 0)
        ) {
          datasetsIncluded.push(dataset);
        }
      });

      setDatasets(datasetsIncluded);
    } else {
      setEfficiencyData(null);
      setDatasets(null);
    }

    // Populate filters
    const tasksListStart = [{value: 'Total', label: 'Total'}];
    const tasks = [];
    const taskIds = operationsData ? Object.keys(operationsData.tasksObject) : [''];
    taskIds.forEach((taskId) => {
      if (taskId == '') {
        tasksListStart.push({value: taskId, label: 'No Task Found'});
      } else {
        let taskName = '';
        if (typeof taskConfigs[taskId] !== 'undefined') {
          taskName = taskConfigs[taskId].name;
        }
        tasks.push({value: taskId, label: taskName});
      }
    });
    tasks.sort((a, b) => {
      return a.label.localeCompare(b.label);
    });
    setInnerSubsetOptions(tasksListStart.concat(tasks));
  }

  function handleEfficiencyZonesFiltersOld(select, selectedZoneLevel = null) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }
    const value = select.multiple ? selected : selected[0];

    if (select.name.includes('efficiencyZoneNames')) {
      setEfficiencyFilters((values) => {
        return {
          ...values,
          efficiencyZoneNames: {
            ...values.efficiencyZoneNames,
            [selectedZoneLevel]: value,
          },
        };
      });
    } else {
      const name = select.name;

      setEfficiencyFilters((values) => {
        return {...values, [name]: value};
      });
    }
  }

  function handleEfficiencyZonesFilters(selectName, value, selectedZoneLevel = null) {
    if (selectName.includes('efficiencyZoneNames')) {
      setEfficiencyFilters((values) => {
        return {
          ...values,
          efficiencyZoneNames: {
            ...values.efficiencyZoneNames,
            [selectedZoneLevel]: value,
          },
        };
      });
    } else {
      setEfficiencyFilters((values) => {
        return {...values, [selectName]: value};
      });
    }
  }

  function efficiencyGraph(dollarRateText = null) {
    const dollarRateTextShow = dollarRateText != null;
    let taskHoverDisplay;
    let taskName = selectedInnerSubset;
    if (typeof taskConfigs[selectedInnerSubset] !== 'undefined') {
      taskName = taskConfigs[selectedInnerSubset].name;
    } else if (
      selectedInnerSubset.constructor === Array &&
      selectedInnerSubset.length == 1 &&
      typeof taskConfigs[selectedInnerSubset[0]] !== 'undefined'
    ) {
      taskName = taskConfigs[selectedInnerSubset[0]].name;
    } else if (selectedInnerSubset.constructor === Array && selectedInnerSubset.length > 1) {
      taskHoverDisplay = selectedInnerSubset
        .map((taskId) => {
          if (taskConfigs.hasOwnProperty(taskId)) {
            return ' ' + taskConfigs[taskId].name + ' ';
          } else {
            return ' No Task Found';
          }
        })
        .toString();
      taskName = 'Multiple Tasks Selected' + ` (${selectedInnerSubset.length})`;
    }

    return (
      <div style={{height: '50vh', minHeight: '400px'}}>
        {/* Chart */}
        {props.pageStates && Array.isArray(selectedInnerSubset) && (
          <Tooltip title={taskHoverDisplay}>
            <div
              style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '20%',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 10,
                opacity: 0,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                // Ensure the chart is still interactable
              }}
            ></div>
          </Tooltip>
        )}
        <div style={{width: '100%', height: '100%'}}>
          <BarChart
            data={{
              labels: efficiencyData.labels,
              datasets: datasets,
            }}
            options={{
              maintainAspectRatio: false,
              plugins: {
                annotation: {
                  annotations: {
                    line1: {
                      type: 'line',
                      scaleID: scaleId,
                      value:
                        targetsObject.hasOwnProperty(selectedInnerSubset) &&
                        targetsObject[selectedInnerSubset][displayedAxis],
                      enValue:
                        targetsObject.hasOwnProperty(selectedInnerSubset) &&
                        targetsObject[selectedInnerSubset][displayedAxis],

                      borderColor: 'rgb(255, 99, 132)',
                      borderWidth: 2,
                      display:
                        targetsObject.hasOwnProperty(selectedInnerSubset) &&
                        typeof targetsObject[selectedInnerSubset][displayedAxis] != 'undefined' &&
                        targetsObject[selectedInnerSubset][displayedAxis] != 0,
                    },
                  },
                },
                subtitle: {
                  display: dollarRateTextShow,
                  text: dollarRateText,
                },
                title: {
                  display: true,
                  text: `Efficiency Baseline${taskName != 'Total' && taskName != '' ? ' By Task' : ''} - ${
                    taskName != '' ? taskName : 'No Task Found'
                  }`,
                  font: {
                    size: customChartProperties.titleFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                legend: {
                  onClick: (e) => {
                    return false;
                  },
                  labels: {
                    font: {
                      size: customChartProperties.legendFontSize,
                    },
                  },
                },
              },
              scales: {
                AcHr: {
                  title: {
                    text: `${capitalizeFirstLetter(unitsArea)} / Hr`, 
                    display: true
                  },
                  display: displayedAxis == 'Ac/Hr',
                  type: 'linear',
                  position: 'left',
                },
                HrAc: {
                  title: {
                    text: `Hr / ${capitalizeFirstLetter(unitsArea)}`, 
                    display: true
                  },
                  display: displayedAxis == `Hr/Ac`,
                  type: 'linear',
                  position: 'left',
                },
                Speed: {
                  title: {text: `Avg Speed (${speedUnits})`, display: true},
                  display: displayedAxis == 'Speed',
                  type: 'linear',
                  position: 'left',
                },
                'Total $': {
                  title: {text: `Total $`, display: true},
                  display: displayedAxis == 'Total $',
                  type: 'linear',
                  position: 'left',
                },
                TargetAxis: {
                  title: {text: 'Target', display: false},
                  display: false,
                  type: 'linear',
                  position: 'left',
                },
                x: {
                  title: {text: String(selectedZoneLevel), display: true},
                },
              },
            }}
          />
        </div>
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    const pageStates = props.pageStates;
    let dollarRateText;
    if (pageStates != undefined && pageStates.hasOwnProperty('acreDollarRate') && pageStates.acreDollarRate != '') {
      const dollarPerAreaConverted = `${unitsAreaConversion(parseFloat(pageStates.acreDollarRate), 'ac', unitsArea).toFixed(2)}`;
      dollarRateText = `($${dollarPerAreaConverted} per ${capitalizeFirstLetter(unitsAreaSystem)})`;
    } else if (
      pageStates != undefined &&
      pageStates.hasOwnProperty('acreDollarRate') &&
      pageStates.hourDollarRate != ''
    ) {
      dollarRateText = `($${pageStates.hourDollarRate} per Hour)`;
    }
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiEfficiency' />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!efficiencyData || !datasets ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            efficiencyGraph(dollarRateText)
          )}
        </div>
      </React.Fragment>
    );
  }
  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Tooltip and Add to Scorecard*/}
      <Box sx={StatsViewReportTooltipRowStyle}>
        {/* Tooltip */}
        <KpiTooltip selectedSubset='kpiEfficiency' />
        {/* Add to Scorecard Button & Modal */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <ScorecardSelectHolder
            autofillPageName={'Farming Efficiency'}
            pageFilters={efficiencyFilters}
            pageStates={{
              selectedInnerSubset: selectedInnerSubset,
              selectedZoneLevel: selectedZoneLevel,
              displayedAxis: displayedAxis,
              scaleId: scaleId,
              hourDollarRate: hourDollarRate,
              acreDollarRate: `${unitsAreaConversion(parseFloat(acreDollarRate), unitsArea, 'ac')}`,
              pageTargets: targetsObject,
            }}
          />
        </Box>
      </Box>

      {!efficiencyData || !datasets ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* Filters */}
          <StatsViewReportFiltersWrapper key={`EfficiencyFilter${selectedZoneLevel}`}>
            {/* Task Filter */}
            {/* <FormControl sx={{backgroundColor:'white'}} fullWidth> */}
              {/* <InputLabel>Tasks</InputLabel> */}
              <IntelliSearchSelect
                inputLabel={"Tasks"}
                size='small'
                label='Tasks'
                displayEmpty
                multiple
                allNoneEnabled={true}
                search
                sx={{borderRadius:'3px'}}
                // Custom styling for selected item
                // Making the selected highlight contrast higher to make it more visible
                MenuProps={{
                  sx: {
                    '&& .Mui-selected': {
                      backgroundColor: '#4e73df',
                    },
                  },
                }}
                value={selectedInnerSubset.constructor === Array ? selectedInnerSubset : [selectedInnerSubset]}
                onChange={(e) => {
                  if (e.length > 1 && e.includes('Total')) {
                    // If we did have total selected before and selected something else, remove total
                    if (selectedInnerSubset.includes('Total')) {
                      const totalIndex = e.indexOf('Total');
                      e.splice(totalIndex, 1);
                    }
                    // If we didn't have total selected before, then we're trying to select total so make the array just total as it cannot be combined with others
                    else {
                      e = ['Total'];
                    }
                  }
                  if (e.constructor !== Array || e.length > 0) {
                    console.log("updating", e)
                    setSelectedInnerSubset(e);
                  }
                  else if(e.length == 0){
                    setSelectedInnerSubset(['Total']);
                  }
                }}
                options={innerSubsetOptions}
              >
                {/* {innerSubsetOptions.map((option, idx) => {
                  return (
                    <MenuItem key={idx} value={option.value}>
                      {option.label}
                    </MenuItem>
                  );
                })} */}
              </IntelliSearchSelect>
            {/* </FormControl> */}
            {/* Zone Name Filter */}
            <IntelliSearchSelect
                inputLabel={selectedZoneLevel + "s"}
                allNoneEnabled={true}
                id={'dashboard-efficiency-efficiencyZone-filter' + selectedZoneLevel}
                key={'dashboard-efficiency-efficiencyZone-filter' + selectedZoneLevel}
                name='efficiencyZoneNames'
                multiple={true}
                search={true}
                // disabled={taskActive || customerSettings.landing.scanAutoStartEnabled}
                value={efficiencyFilters.efficiencyZoneNames[selectedZoneLevel]}
              options={zoneFilterOptions.map((opt) => {return {"label": opt, "value":opt}})}
              onChange={(s) => {

                handleEfficiencyZonesFilters('efficiencyZoneNames', s, selectedZoneLevel);
              }}
              placeholder='Filter by Zone Name'
              />
            
            {/* Row Spacing Filter */}
            {selectedZoneLevel == 'Block' && (
              <IntelliSearchSelect
                inputLabel={"Row Spacing"}
                allNoneEnabled={true}
                id='dashboard-efficiency-efficiencyZoneRowSpacing-filter'
                key={'dashboard-efficiency-efficiencyZoneRowSpacing-filter' + selectedZoneLevel}
                name='efficiencyRowSpacing'
                multiple={true}
                value={efficiencyFilters.efficiencyRowSpacing}
                options={filterOptions.efficiency.efficiencyRowSpacing.map((opt) => {return {"label": opt.text, "value":opt.value}})}
                onChange={(s) => {
                  handleEfficiencyZonesFilters('efficiencyRowSpacing', s);
                }}
                placeholder='Filter by Block Row Spacing'
              />
            )}
          </StatsViewReportFiltersWrapper>
          {!smallScreen && <Grid container>
            {/* Y-Axis Additional Input  IN NON SMALL SCREEN MODE*/}
          <Grid item xs={6} sx={{display: 'flex', justifyContent: 'start', padding: '20px', alignItems:'end'}} ref={additionalInputRef}>
              {customerSettings.dashboard.efficiencyDollarsCalculatorEnabled && (
                <Box
                  sx={{
                    '.MuiInputBase-input': {
                      padding: '5px',
                    },
                    display: 'flex',
                    padding: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: {xs: 'column', md: 'row'},
                    boxShadow:
                      displayedAxis == 'Total $' && !acreDollarRate && !hourDollarRate
                        ? '0px 3px 10px 0px #ff9966'
                        : '0px 3px 10px 0px #aaaaaa',
                    borderRadius: '4px',
                  }}
                >
                  <FormControl sx={{maxWidth: '160px'}}>
                    <InputLabel shrink>{unitsAreaSystem == 'acre' ? 'Acre Rate' : 'Hectare Rate'}</InputLabel>
                    <OutlinedInput
                      endAdornment={<InputAdornment position='end'>{`${unitsAreaSystem == 'acre' ? '$/Ac' : '$/Ha'}`}</InputAdornment>}
                      notched
                      label={`${unitsAreaSystem == 'acre' ? 'Acre Rate' : 'Hectare Rate'}`}
                      size='small'
                      onChange={handleAcreDollarRateChange}
                      value={acreDollarRate}
                    />
                  </FormControl>
                  <Divider orientation={smallScreen ? 'horizontal' : 'vertical'}>or</Divider>
                  <FormControl sx={{maxWidth: '160px'}}>
                    <InputLabel shrink>Hour Rate</InputLabel>
                    <OutlinedInput
                      endAdornment={<InputAdornment position='end'>$/Hr</InputAdornment>}
                      notched
                      label='Hour Rate'
                      size='small'
                      onChange={handleHourDollarRateChange}
                      value={hourDollarRate}
                    />
                  </FormControl>
                </Box>
              )}
            </Grid>
          {/* Sort By */}
            <Grid item xs={6}>
            <StatsViewReportSortByWrapper key={`EfficiencySort${selectedZoneLevel}`}>
              <IntelliSearchSelect
                id='dashboard-efficiency-efficiencyZoneSort-filter'
                key={'dashboard-efficiency-efficiencyZoneSort-filter' + selectedZoneLevel}
                name='efficiencyZoneSort'
                multiple={false}
                value={efficiencyFilters.efficiencyZoneSort}
                options={filterOptions.efficiency.efficiencyZoneSort.map((opt) => {return {"label": opt.text, "value":opt.value}})}
                onChange={(s) => {
                    handleEfficiencyZonesFilters('efficiencyZoneSort', s);
                  }}
                placeholder='Sorting method'
              />
              {/* Zone Count */}
              <IntelliSearchSelect
                id='dashboard-efficiency-efficiencyZoneCount-filter'
                key={'dashboard-efficiency-efficiencyZoneCount-filter' + selectedZoneLevel}
                name='efficiencyZoneCount'
                multiple={false}
                value={efficiencyFilters.efficiencyZoneCount}
                options={filterOptions.efficiency.efficiencyZoneCount.map((opt) => {return {"label": opt.text, "value":opt.value}})}
                onChange={(s) => {
                  handleEfficiencyZonesFilters('efficiencyZoneCount', s);
                }}
                placeholder={`Show  All ${selectedZoneLevel}s`}
              />
            </StatsViewReportSortByWrapper>
            </Grid>
          </Grid>}
          {/* In Small screen mode sort by display */}
          {smallScreen && <StatsViewReportSortByWrapper key={`EfficiencySort${selectedZoneLevel}`}>
              <IntelliSearchSelect
                id='dashboard-efficiency-efficiencyZoneSort-filter'
                key={'dashboard-efficiency-efficiencyZoneSort-filter' + selectedZoneLevel}
                name='efficiencyZoneSort'
                multiple={false}
                value={efficiencyFilters.efficiencyZoneSort}
                options={filterOptions.efficiency.efficiencyZoneSort.map((opt) => {return {"label": opt.text, "value":opt.value}})}
                onChange={(s) => {
                    handleEfficiencyZonesFilters('efficiencyZoneSort', s);
                  }}
                placeholder='Sorting method'
              />
              {/* Zone Count */}
              <IntelliSearchSelect
                id='dashboard-efficiency-efficiencyZoneCount-filter'
                key={'dashboard-efficiency-efficiencyZoneCount-filter' + selectedZoneLevel}
                name='efficiencyZoneCount'
                multiple={false}
                value={efficiencyFilters.efficiencyZoneCount}
                options={filterOptions.efficiency.efficiencyZoneCount.map((opt) => {return {"label": opt.text, "value":opt.value}})}
                onChange={(s) => {
                  handleEfficiencyZonesFilters('efficiencyZoneCount', s);
                }}
                placeholder={`Show  All ${selectedZoneLevel}s`}
              />
            </StatsViewReportSortByWrapper>}
          {/* Graph and XY Axis Control */}
          <Grid container sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}} spacing={0}>
            {/* Y-Axis Controls */}
            <Grid
              item
              xs={2}
              lg={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  '.MuiInputBase-input': {
                    padding: '5px',
                  },
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                  transformOrigin: 'center',
                  transform: 'rotate(270deg)',
                  margin: 'auto',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    boxShadow: '-5px -5px 10px 0px #aaaaaa',
                    borderRadius: '4px',
                  }}
                >
                  <FormControl sx={{m: 1}}>
                    <InputLabel sx={{maxWidth:'none'}} >Measure by</InputLabel>
                    <Select
                      sx={{padding:'3px'}}
                      size='small'
                      label='Measure by'
                      value={displayedAxis}
                      onChange={(e) => {
                        setDisplayedAxis(e.target.value);
                        setScaleId(e.target.value.replace('/', ''));
                        setAcreDollarRate('');
                        setHourDollarRate('');
                      }}
                    >
                      <MenuItem  value={'Ac/Hr'}>{`${capitalizeFirstLetter(unitsArea)} / Hr`}</MenuItem>
                      <MenuItem  value={`Hr/Ac`}>{`Hr / ${capitalizeFirstLetter(unitsArea)}`}</MenuItem>
                      <MenuItem  value={'Speed'}>{`Avg Speed (${speedUnits})`}</MenuItem>
                      <MenuItem  value={'Total $'}>{'Cost'}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            {/* Graph */}
            <Grid item xs={10} lg={9} sx={{textAlign: 'center'}}>
              {efficiencyGraph()}
            </Grid>
            {/* Y-Axis Additional Input  IN SMALL SCREEN MODE*/}
            {smallScreen && <Grid item xs={6} sx={{display: 'flex', justifyContent: 'center'}} ref={additionalInputRef}>
              {customerSettings.dashboard.efficiencyDollarsCalculatorEnabled && (
                <Box
                  sx={{
                    '.MuiInputBase-input': {
                      padding: '5px',
                    },
                    display: 'flex',
                    padding: '10px',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: {xs: 'column', md: 'row'},
                    boxShadow:
                      displayedAxis == 'Total $' && !acreDollarRate && !hourDollarRate
                        ? '0px 3px 10px 0px #ff9966'
                        : '0px 3px 10px 0px #aaaaaa',
                    borderRadius: '4px',
                  }}
                >
                  <FormControl sx={{maxWidth: '160px'}}>
                    <InputLabel shrink>{unitsAreaSystem == 'acre' ? 'Acre Rate' : 'Hectare Rate'}</InputLabel>
                    <OutlinedInput
                      endAdornment={<InputAdornment position='end'>{`${unitsAreaSystem == 'acre' ? '$/Ac' : '$/Ha'}`}</InputAdornment>}
                      notched
                      label={`${unitsAreaSystem == 'acre' ? 'Acre Rate' : 'Hectare Rate'}`}
                      size='small'
                      onChange={handleAcreDollarRateChange}
                      value={acreDollarRate}
                    />
                  </FormControl>
                  <Divider orientation={smallScreen ? 'horizontal' : 'vertical'}>or</Divider>
                  <FormControl sx={{maxWidth: '160px'}}>
                    <InputLabel shrink>Hour Rate</InputLabel>
                    <OutlinedInput
                      endAdornment={<InputAdornment position='end'>$/Hr</InputAdornment>}
                      notched
                      label='Hour Rate'
                      size='small'
                      onChange={handleHourDollarRateChange}
                      value={hourDollarRate}
                    />
                  </FormControl>
                </Box>
              )}
            </Grid>}
            {/* X-Axis Control */}
            <Grid
              item
              xs={smallScreen ? 6 : 12}
              sx={{display: 'flex', alignItems: 'center', justifyContent: 'center', textAlign: 'center'}}
            >
              <FormControl sx={{minWidth: '120px', m: 1}}>
                <InputLabel>View by</InputLabel>
                <Select
                  size='small'
                  label='View by'
                  value={selectedZoneLevel}
                  onChange={(e) => {
                    setSelectedZoneLevel(e.target.value);
                  }}
                >
                  <MenuItem value={'Region'}>{'Region'}</MenuItem>
                  <MenuItem value={'Field'}>{'Field'}</MenuItem>
                  <MenuItem value={'Block'}>{'Block'}</MenuItem>
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiEfficiency};
