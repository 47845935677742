import React, {useState, useEffect} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, isNumeric} from '../../../app/utils';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {genServiceCostsGraphData} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportTooltipRowStyle,
} from '../dashboardUtils';
import {
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

function KpiServiceCosts(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const servicesData = useSelector((state) => {
    return state.dashboard.servicesData;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });

  const [serviceCostsData, setServiceCostsData] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [vehicleSnOptions, setVehicleSnOptions] = useState([]);
  const [displayedAxis, setDisplayedAxis] = useState('Total');
  const [stackedComparison, setStackedComparison] = useState(false);

  const [serviceCostsFilters, setServiceCostsFilters] = useState({
    serviceCostsVehicleSNs: [],
    serviceCostsVehicleType: [],
    serviceCostsVehicleCount: '10',
    serviceCostsVehicleSort: 'Desc',
  });

  useEffect(() => {
    if (servicesData != null) {
      generateData();
    }
  }, [servicesData, vehicleSNDict, serviceCostsFilters, displayedAxis, stackedComparison]);

  useEffect(() => {
    if (servicesData != null) {
      generateVehicleFilterOptions();
    }
  }, [servicesData]);

  useEffect(() => {
    if (props.pageStates?.displayedAxis) {
      setDisplayedAxis(props.pageStates?.displayedAxis);
    } else {
      setDisplayedAxis('Total');
    }
    if (props.pageStates?.stackedComparison) {
      setStackedComparison(props.pageStates?.stackedComparison);
    } else {
      setStackedComparison(false);
    }
    if (props?.pageFilters) {
      setServiceCostsFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageStates, props.pageFilters]);

  function generateData() {
    // Generate data
    const serviceCostsDataTemp = genServiceCostsGraphData(
      servicesData,
      displayedAxis,
      serviceCostsFilters,
      vehicleSNDict
    );
    if (Object.keys(servicesData.vehicleServices).length > 0) {
      setServiceCostsData(serviceCostsDataTemp);
    } else {
      setServiceCostsData(null);
    }

    const tempDatasets = [];
    serviceCostsDataTemp.datasets.forEach((setObj) => {
      const splitSetObj = setObj.id.split('.');
      if (setObj.id == displayedAxis && stackedComparison == false) {
        tempDatasets.push(setObj);
      } else if (splitSetObj[0] == displayedAxis && setObj.id.includes('.') && stackedComparison == true) {
        tempDatasets.push(setObj);
      }
    });
    setDatasets(tempDatasets);
  }

  function generateVehicleFilterOptions() {
    // Get vehicle filter options
    const newVehicleSnOptions = Object.keys(servicesData.vehicleServices).map((vehicleSN) => {
      return {value: vehicleSN, text: servicesData.vehicleServices[vehicleSN].vehicleName};
    });
    setVehicleSnOptions(newVehicleSnOptions);
  }

  function handleVehiclesFilters(value, selectName) {
    setServiceCostsFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function handleVehiclesFiltersOld(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    const value = select.multiple ? selected : selected[0];
    const name = select.name;
    setServiceCostsFilters((values) => {
      return {...values, [name]: value};
    });
  }

  function serviceCostsGraph() {
    let graphTitle;
    let yAxisTitle;
    let xAxisTitle;

    switch (displayedAxis) {
      case 'PartsCosts':
        graphTitle = 'Total Parts Costs';
        yAxisTitle = 'Parts Costs ($)';
        xAxisTitle = 'Vehicle';
        break;
      case 'LaborCosts':
        graphTitle = 'Total Labor Costs';
        yAxisTitle = 'Labor Costs ($)';
        xAxisTitle = 'Vehicle';
        break;
      case 'LaborTime':
        graphTitle = 'Total Labor Time';
        yAxisTitle = 'Labor Time (hrs)';
        xAxisTitle = 'Vehicle';
        break;
      default:
        graphTitle = 'Total Service Costs (Parts + Labor)';
        yAxisTitle = 'Service Costs ($)';
        xAxisTitle = 'Vehicle';
    }

    return (
      <div style={{height: '50vh', minHeight: '400px'}}>
        {/* Chart */}
        <BarChart
          data={{...serviceCostsData, datasets: datasets}}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: graphTitle,
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              leftAxis: {
                stacked: stackedComparison,
                title: {
                  text: yAxisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                position: 'left',
              },
              x: {
                stacked: stackedComparison,
                ticks: {
                  autoSkip: serviceCostsData.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 90,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: xAxisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {
    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiServiceCosts' />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!serviceCostsData ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            serviceCostsGraph()
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Tooltip and Add to Scorecard*/}
      <Box sx={StatsViewReportTooltipRowStyle}>
        {/* Tooltip */}
        <KpiTooltip selectedSubset='kpiServiceCosts' />
        {/* Add to Scorecard Button & Modal */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <ScorecardSelectHolder
            autofillPageName={'Service Costs'}
            pageFilters={serviceCostsFilters}
            pageStates={{
              displayedAxis: displayedAxis,
              stackedComparison: stackedComparison,
            }}
          />
        </Box>
      </Box>

      {!serviceCostsData ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* Filters */}
          <StatsViewReportFiltersWrapper key={`serviceCostsFilter`}>
            {/* Vehicle Filter */}
            <IntelliSearchSelect
              allNoneEnabled
              inputLabel={"Vehicles"}
              id='dashboard-vehicles-serviceCostsVehicleSNs-filter'
              name='serviceCostsVehicleSNs'
              multiple={true}
              search={true}
              value={serviceCostsFilters.serviceCostsVehicleSNs}
              options={vehicleSnOptions.map((opt) => {
                return {"label": opt.text, "value":opt.value}
              })}
              onChange={(s) => {
                handleVehiclesFilters(s, "serviceCostsVehicleSNs");
              }}
              placeholder='Filter for Vehicles'
            />
            {/* Vehicle Type Filter */}
            <IntelliSearchSelect
              allNoneEnabled
              inputLabel={"Vehicle Type"}
              id='dashboard-vehicles-serviceCostsVehicleType-filter'
              name='serviceCostsVehicleType'
              multiple={true}
              search={true}
              value={serviceCostsFilters.serviceCostsVehicleType}
              options={filterOptions.vehicles.vehicleType.map((opt) => {
                return {"label": opt.text, "value":opt.value}
              })}
              onChange={(s) => {
                handleVehiclesFilters(s, "serviceCostsVehicleType");
              }}
              placeholder='Filter for Vehicle Type'
            />
          </StatsViewReportFiltersWrapper>
          {/* Sort By */}
          <StatsViewReportSortByWrapper key={`serviceCostsSort`}>
            <IntelliSearchSelect
              id='dashboard-vehicles-serviceCostsVehicleSort-filter'
              name='serviceCostsVehicleSort'
              multiple={false}
              value={serviceCostsFilters.serviceCostsVehicleSort}
              options={filterOptions.vehicles.efficiencyVehicleSort.map((opt) => {
                return {"label": opt.text, "value":opt.value}
              })}
              onChange={(s) => {
                handleVehiclesFilters(s, "serviceCostsVehicleSort");
              }}
              placeholder='Sorting method'
            />
            {/* Vehicle Count Filter */}
            <IntelliSearchSelect
              id='dashboard-vehicles-serviceCostsVehicleCount-filter'
              name='serviceCostsVehicleCount'
              multiple={false}
              value={serviceCostsFilters.serviceCostsVehicleCount}
              options={filterOptions.vehicles.vehicleCount.map((opt) => {
                return {"label": opt.text, "value":opt.value}
              })}
              onChange={(s) => {
                handleVehiclesFilters(s, "serviceCostsVehicleCount");
              }}
              placeholder={`Show  All Vehicles`}
            />
          </StatsViewReportSortByWrapper>
          <Grid container sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}} spacing={0}>
            {/* Y-Axis Controls */}
            <Grid
              item
              xs={2}
              lg={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  '.MuiInputBase-input': {
                    padding: '5px',
                  },
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                  transformOrigin: 'center',
                  transform: 'rotate(270deg)',
                  margin: 'auto',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    boxShadow: '-5px -5px 10px 0px #aaaaaa',
                    borderRadius: '4px',
                  }}
                >
                  <FormControl sx={{m: 1}}>
                    <InputLabel sx={{maxWidth:'none'}}>Measure by</InputLabel>
                    <Select
                      sx={{padding:'3px'}}
                      size='small'
                      label='Measure by'
                      value={displayedAxis}
                      onChange={(e) => {
                        setDisplayedAxis(e.target.value);
                      }}
                    >
                      <MenuItem value={'Total'}>{'Total Costs'}</MenuItem>
                      <MenuItem value={'PartsCosts'}>{'Parts Costs'}</MenuItem>
                      <MenuItem value={'LaborCosts'}>{'Labor Costs'}</MenuItem>
                      <MenuItem value={'LaborTime'}>{'Labor Time'}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            {/* Graph */}
            <Grid item xs={10} lg={9} sx={{textAlign: 'center', alignItems: 'center'}}>
              {serviceCostsGraph()}
            </Grid>
          </Grid>
          <div className='col-12 flex-col text-center px-0 align-items-center my-auto'>
            <div className='text-center align-items-center my-auto'>
              <FormControlLabel
                control={
                  <Checkbox
                    onChange={() => {
                      setStackedComparison(!stackedComparison);
                    }}
                    checked={stackedComparison}
                  />
                }
                label='Compare Scheduled / Unscheduled'
              />
            </div>
          </div>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiServiceCosts};
