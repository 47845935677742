import React, {useState, useEffect} from 'react';
import {createPortal} from 'react-dom';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {
  sendGAEvent,
  fetchPostAuthSafe,
  unitsLengthDisplayConversion,
  unitsLengthSubmitConversion,
  sortVehicleNamesHelper,
  intRegex,
  dollarRegex,
  isNumeric,
  handleAllowOnlyNumbers,
} from '../../../app/utils';
import {
  ModalFramework,
  ModalHeader,
  ModalFooter,
  ModalRowSection,
  ModalColumnSection,
  ModalWideColumnSection,
  ModalInputSection,
  ModalBody,
  ModalWarning,
} from '../../../components/Modal';
import {Tailselect} from '../../../components/Tailselect';
import {getServiceData} from '../shopviewSlice';
import Alert from '@mui/material/Alert';

const defaultInputs = {
  vehicle: [],
  vehicleSN: [],
  currOdometer: 0,
  currEngineHours: 0,
  machineType: [],
  task: '',
  recurring: true,
  timeInterval: '',
  timeIntervalUnits: 'days',
  engineHoursInterval: '',
  odometerInterval: '',
  nextServiceDate: '',
  nextServiceEngineHours: '',
  nextServiceOdometer: '',
  averageLaborHours: '',
  averageLaborCost: '',
  averagePartsCost: '',
  notes: '',
};

function NewTaskModal(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const vehiclesLookUp = useSelector((state) => {
    return state.shopview.vehiclesLookUp;
  });
  const odoByVehiclesnUnitsKm = useSelector((state) => {
    return state.shopview.odoByVehiclesnUnitsKm;
  });
  const engHrByVehiclesnUnitsHrs = useSelector((state) => {
    return state.shopview.engHrByVehiclesnUnitsHrs;
  });
  const serviceTasks = useSelector((state) => {
    return state.shopview.serviceTasks;
  });
  const unitsLengthSystem = useSelector((state) => {
    return state.app.userSettings.general.unitsLength;
  });
  const userSettings = useSelector((state) => {
    return state.app.userSettings;
  });
  const customerSettings = useSelector((state) => {
    return state.app.customerSettings;
  });

  const [inputs, setInputs] = useState(defaultInputs);
  const [warnings, setWarnings] = useState({});
  const [selectOptions, setSelectOptions] = useState({
    vehicles: [],
  });
  const [unitsLength, setUnitsLength] = useState('km');
  const [submitting, setSubmitting] = useState(false);

  const disableInterval = !inputs.recurring;
  const multipleVehicle = inputs.vehicleSN.length > 1;
  const multiVehicleEnabled =
    customerSettings.shopview.newServiceMultiVehicleAdminOnly == false ||
    ['customer_admin', 'ic_admin'].includes(userSettings.role);

  useEffect(() => {
    // Determine length units based on customer length units system
    if (unitsLengthSystem == 'imperial') {
      setUnitsLength('mi');
    } else {
      setUnitsLength('km');
    }
  }, [unitsLengthSystem]);

  useEffect(() => {
    function modalOpen() {
      sendGAEvent('modal_open', 'NewTask', 'shopview');
      setDefault();
    }
    // Set default values when modal opened
    $('#shopview-new-task-modal').on('show.bs.modal', modalOpen);
    return () => {
      // Remove event listener when component is unmounted
      $('#shopview-new-task-modal').off('show.bs.modal', modalOpen);
    };
  }, [vehiclesLookUp]);

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
    const tomorrow = getTomorrow();
    if (
      (name == 'nextServiceEngineHours' && value < inputs.currEngineHours) ||
      (name == 'nextServiceOdometer' && value < unitsLengthDisplayConversion(inputs.currOdometer, unitsLength)) ||
      (name == 'nextServiceDate' && value < tomorrow)
    ) {
      setWarnings((values) => {
        return {
          ...values,
          warningNextServiceDue: true,
        };
      });
    } else if (
      (name == 'nextServiceEngineHours' && value >= inputs.currEngineHours) ||
      (name == 'nextServiceOdometer' && value >= unitsLengthDisplayConversion(inputs.currOdometer, unitsLength)) ||
      (name == 'nextServiceDate' && value >= tomorrow)
    ) {
      setWarnings((values) => {
        return {
          ...values,
          warningNextServiceDue: false,
        };
      });
    }
    if (['timeInterval', 'engineHoursInterval', 'odometerInterval'].includes(name) && value && !intRegex.test(value)) {
      return;
    }
    if (
      [
        'averageLaborHours',
        'averageLaborCost',
        'averagePartsCost',
        'nextServiceEngineHours',
        'nextServiceOdometer',
      ].includes(name) &&
      value &&
      (!dollarRegex.test(value) || value.includes('-'))
    ) {
      return;
    }
    setInputs((values) => {
      return {...values, [name]: value};
    });
    if (event.target.type === 'checkbox' && !value) {
      setInputs((values) => {
        return {
          ...values,
          timeInterval: '',
          timeIntervalUnits: 'days',
          engineHoursInterval: '',
          odometerInterval: '',
        };
      });
    }
  };

  const handleSelectChange = (select) => {
    const name = select.name;
    const value = select.value;
    setInputs((values) => {
      return {...values, [name]: value};
    });
  };

  function setDefault() {
    setWarnings({});
    setInputs(defaultInputs);
    vehicleOptions();
  }

  function vehicleOptions() {
    // Add options to vehicle select dropdown
    const vehicleOptions = [];

    // eslint-disable-next-line guard-for-in
    for (const serialNumber in vehiclesLookUp) {
      if (vehiclesLookUp.hasOwnProperty(serialNumber)) {
        if (vehiclesLookUp[serialNumber]?.shopActive === false) {
          continue;
        }
        vehicleOptions.push({value: serialNumber, text: vehiclesLookUp[serialNumber].name});
      }
    }

    vehicleOptions.sort((a, b) => {
      return sortVehicleNamesHelper(a.text, b.text);
    });

    setSelectOptions((values) => {
      return {...values, vehicles: vehicleOptions};
    });
    // Trigger a vehicle select once populated
    if (!multiVehicleEnabled) {
      if (vehicleOptions.length > 0) {
        vehicleSelectDefault(vehicleOptions);
      }
    }
  }

  function vehicleSelect(select) {
    const vehicleNames = [];
    const vehicleSNs = [];
    const vehicleMachineTypes = [];

    const selectObj = tail.select(`#${'shopview-new-task-vehicle'}`);
    const selectedOptions = [...selectObj.options.selected];
    for (let i = 0; i < selectedOptions.length; i++) {
      const selectedVehicleSN = selectedOptions[i].value;
      if (Object.prototype.hasOwnProperty.call(vehiclesLookUp, selectedVehicleSN)) {
        vehicleSNs.push(selectedVehicleSN);
        vehicleNames.push(vehiclesLookUp[selectedVehicleSN].name);
        vehicleMachineTypes.push(vehiclesLookUp[selectedVehicleSN].machineType);
      }
    }

    const multiVehicle = vehicleSNs.length > 1;

    let currOdometer = 0;
    let currEngineHours = 0;

    if (!multiVehicle) {
      if (Object.prototype.hasOwnProperty.call(odoByVehiclesnUnitsKm, vehicleSNs[0])) {
        currOdometer = odoByVehiclesnUnitsKm[vehicleSNs[0]] || 0;
      }
      if (Object.prototype.hasOwnProperty.call(engHrByVehiclesnUnitsHrs, vehicleSNs[0])) {
        currEngineHours = engHrByVehiclesnUnitsHrs[vehicleSNs[0]] || 0;
      }
    }

    const newInputs = {
      vehicle: vehicleNames,
      vehicleSN: vehicleSNs,
      currOdometer: currOdometer,
      currEngineHours: currEngineHours,
      machineType: vehicleMachineTypes,
    };

    // Clear next service data if multivehcle selection
    if (multiVehicle) {
      newInputs.nextServiceDate = '';
      newInputs.nextServiceEngineHours = '';
      newInputs.nextServiceOdometer = '';
      newInputs.recurring = true;
    }

    setInputs((values) => {
      return {
        ...values,
        ...newInputs,
      };
    });
  }

  function vehicleSelectDefault(vehicleOptions) {
    if (multiVehicleEnabled) {
      setInputs(defaultInputs);
    } else {
      let vehicle;
      let vehicleSN;
      let machineType;
      let currOdometer;
      let currEngineHours;

      if (vehicleOptions.length > 0) {
        vehicleSN = vehicleOptions[0].value;
        vehicle = vehiclesLookUp[vehicleSN].name;
        machineType = vehiclesLookUp[vehicleSN].machineType;
        currOdometer = odoByVehiclesnUnitsKm[vehicleSN] || 0;
        currEngineHours = engHrByVehiclesnUnitsHrs[vehicleSN] || 0;
      }

      if (typeof vehicleSN === 'undefined') {
        setInputs(defaultInputs);
      } else {
        const newInputs = {
          vehicle: [vehicle],
          vehicleSN: [vehicleSN],
          currOdometer: currOdometer,
          currEngineHours: currEngineHours,
          machineType: [machineType],
        };
        setInputs((values) => {
          return {
            ...values,
            ...newInputs,
          };
        });
      }
    }
  }

  async function submitModal() {
    /*
    Check the criteria for accepting the modal entry
    Since warningDataObj has the serviceTask schema,
    we check against it to determine what the serviceTask's required intervals are
    */
    const vehicleEmpty = !inputs.vehicle || inputs.vehicle.length == 0;
    const taskEmpty = inputs.task.trim() === '';
    const intervalsEmpty =
      inputs.timeInterval === '' && inputs.engineHoursInterval === '' && inputs.odometerInterval == '';

    // check if the intervals are values of 0
    const zeroOdo = parseInt(inputs.odometerInterval) === 0;
    const zeroEngHours = parseInt(inputs.engineHoursInterval) === 0;
    const zeroTimeInterval = parseInt(inputs.timeInterval) === 0;
    // combining all checks into a single variable
    const zeroIntervals = zeroOdo || zeroEngHours || zeroTimeInterval;

    // Check if any non-deleted task with the same name already exist for the vehicle(s)
    const warnExistVehicles = [];

    for (let i = 0; i < serviceTasks.length; i++) {
      const existingTaskData = serviceTasks[i].data;
      const existingTaskNotDeleted = existingTaskData.deleted != true;
      const existingTaskNameMatched = existingTaskData.name == inputs.task.trim();
      const existingTaskVehicleMatched = inputs.vehicleSN.includes(existingTaskData.vehicleSN);
      if (existingTaskNotDeleted && existingTaskNameMatched && existingTaskVehicleMatched) {
        warnExistVehicles.push(existingTaskData.vehicleSN);
      }
    }

    // Set any warnings
    setWarnings((values) => {
      return {
        ...values,
        warningVehicle: vehicleEmpty,
        warningTask: taskEmpty,
        warningIntervals: intervalsEmpty && inputs.recurring,
        warningZeroIntervals: zeroIntervals,
        warningExists: warnExistVehicles.length > 0,
        warnExistVehicles: warnExistVehicles,
      };
    });

    // If all submit criterias are met POST the data to the server
    if (
      !vehicleEmpty &&
      !taskEmpty &&
      !(intervalsEmpty && inputs.recurring) &&
      !zeroIntervals &&
      !submitting &&
      warnExistVehicles.length == 0
    ) {
      setSubmitting(true);
      // Differentiate between '' vs 0 values
      let engineHoursInterval = parseFloat(inputs.engineHoursInterval);
      if (isNaN(engineHoursInterval)) {
        engineHoursInterval = '';
      }

      let nextServiceEngineHours = parseFloat(inputs.nextServiceEngineHours);
      if (isNaN(nextServiceEngineHours)) {
        nextServiceEngineHours = '';
      }

      let odometerInterval = parseFloat(unitsLengthSubmitConversion(inputs.odometerInterval, unitsLength));
      if (isNaN(odometerInterval)) {
        odometerInterval = '';
      }

      let nextServiceOdometer = parseFloat(inputs.nextServiceOdometer);
      if (isNaN(nextServiceOdometer)) {
        nextServiceOdometer = '';
      }
      const nextServiceOdometerConverted = unitsLengthSubmitConversion(nextServiceOdometer, unitsLength);

      let timeInterval = parseFloat(inputs.timeInterval);
      if (isNaN(timeInterval)) {
        timeInterval = '';
      }

      setSubmitting(true);
      const postData = {
        name: inputs.task.trim(),
        vehicle: inputs.vehicle,
        vehicleSN: inputs.vehicleSN,
        recurring: inputs.recurring,
        timeInterval: timeInterval,
        timeUnits: inputs.timeIntervalUnits.trim(),
        odometerInterval: odometerInterval,
        engineHoursInterval: engineHoursInterval,
        nextServiceDate: inputs.nextServiceDate,
        nextServiceEngineHours: nextServiceEngineHours,
        nextServiceOdometer: nextServiceOdometerConverted,
        averageLaborHours: isNumeric(inputs.averageLaborHours) ? parseFloat(inputs.averageLaborHours) : '',
        averageLaborCost: isNumeric(inputs.averageLaborCost) ? parseFloat(inputs.averageLaborCost) : '',
        averagePartsCost: isNumeric(inputs.averagePartsCost) ? parseFloat(inputs.averagePartsCost) : '',
        notes: inputs.notes.trim(),
      };

      const options = {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      };

      const createNewTaskReq = await fetchPostAuthSafe(
        '/shopview/createNewTask',
        options,
        userSettings.username,
        userSettings.databaseName
      );
      const createNewTaskReqData = await createNewTaskReq.json();
      if (createNewTaskReqData.errorMsg) {
        navigate('/error', {state: {errorMsg: createNewTaskReqData.errorMsg}});
      }

      $('#shopview-new-task-modal').modal('hide');
      setTimeout(function () {
        dispatch(getServiceData());
      }, 500);

      sendGAEvent('modal_submit', 'NewTask', 'shopview');
      setSubmitting(false);
    }
  }

  const getTomorrow = () => {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);

    const year = tomorrow.getFullYear();
    const month = (tomorrow.getMonth() + 1).toString().padStart(2, '0');
    const day = tomorrow.getDate().toString().padStart(2, '0');

    return `${year}-${month}-${day}`;
  };

  return createPortal(
    <ModalFramework id='shopview-new-task-modal'>
      <ModalHeader title='New Service' />
      <ModalBody submitting={submitting}>
        {warnings.warningVehicle && <ModalWarning text='Select a vehicle' />}
        {warnings.warningTask && <ModalWarning text='Enter a task name' />}
        {warnings.warningExists && (
          <ModalWarning
            text={`Task already exists for the vehicles: 
          ${warnings.warnExistVehicles
            .map((vehicleSN) => {
              return vehiclesLookUp[vehicleSN].name;
            })
            .join(', ')}`}
          />
        )}
        <ModalRowSection underline={true}>
          <ModalColumnSection>
            <ModalInputSection label='Vehicle Name'>
              <Tailselect
                class='col-12'
                id='shopview-new-task-vehicle'
                name='vehicle'
                search={true}
                multiple={multiVehicleEnabled}
                value={multiVehicleEnabled ? inputs.vehicle : inputs.vehicle[0]}
                options={selectOptions.vehicles}
                onChange={vehicleSelect}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Service Name'>
              <input
                type='text'
                name='task'
                className='form-control flex-fill'
                value={inputs.task}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        {warnings.warningIntervals && (
          <ModalWarning text='Fill out at least 1 of the 3 interval fields for recurring tasks.' />
        )}
        {multipleVehicle && (
          <Alert variant='outlined' severity='warning'>
            <div style={{textAlign: 'center'}}>
              Only Recurring Service tasks are allowed to be created when selecting multiple vehicles.
            </div>
          </Alert>
        )}
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <div>
              <label>
                Recurring:{' '}
                <input
                  type='checkbox'
                  name='recurring'
                  className='ml-2'
                  checked={inputs.recurring}
                  disabled={multipleVehicle}
                  onChange={handleChange}
                />
              </label>
            </div>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <ModalInputSection label='Time Interval'>
              <input
                type='text'
                name='timeInterval'
                className='form-control flex-fill'
                value={inputs.timeInterval}
                step={1}
                min={0}
                disabled={disableInterval}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
            <ModalInputSection label='Next Service Date'>
              <input
                type='date'
                name='nextServiceDate'
                className='form-control flex-fill'
                value={inputs.nextServiceDate}
                disabled={multipleVehicle}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Interval Units'>
              <Tailselect
                class='col-12'
                id='shopview-new-task-time-unit'
                name='timeIntervalUnits'
                value={inputs.timeIntervalUnits}
                disabled={disableInterval}
                options={['days', 'weeks', 'months']}
                onChange={handleSelectChange}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <ModalInputSection label='Engine Hours Interval (hrs)'>
              <input
                type='text'
                name='engineHoursInterval'
                className='form-control flex-fill'
                value={inputs.engineHoursInterval}
                step={1}
                min={0}
                disabled={disableInterval}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label={`Odometer Interval (${unitsLength})`}>
              <input
                type='text'
                name='odometerInterval'
                className='form-control flex-fill'
                value={inputs.odometerInterval}
                step={1}
                min={0}
                disabled={disableInterval}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <ModalInputSection label='Next Service Due | Engine Hours (hrs)'>
              <input
                type='text'
                name='nextServiceEngineHours'
                className='form-control flex-fill'
                value={inputs.nextServiceEngineHours}
                step={1}
                min={Math.floor(inputs.currEngineHours) || 0}
                disabled={multipleVehicle}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
              {inputs.machineType.length == 1 &&
                inputs.machineType[0] != 3 && ( // Trailer type
                  <span className='font-italic pl-1'>Last Est. Hours: {Math.floor(inputs.currEngineHours)}</span>
                )}
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label={`Next Service Due | Odometer (${unitsLength})`}>
              <input
                type='text'
                name='nextServiceOdometer'
                className='form-control flex-fill'
                value={inputs.nextServiceOdometer}
                step={1}
                min={Math.floor(unitsLengthDisplayConversion(inputs.currOdometer, unitsLength)) || 0}
                disabled={multipleVehicle}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
              {inputs.machineType.length == 1 &&
                inputs.machineType[0] != 3 && ( // Trailer type
                  <span className='font-italic pl-1'>
                    Last Est. Odo: {Math.floor(unitsLengthDisplayConversion(inputs.currOdometer, unitsLength))}
                  </span>
                )}
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <ModalInputSection label='Average Labor Cost ($)'>
              <input
                type='text'
                name='averageLaborCost'
                className='form-control flex-fill'
                value={inputs.averageLaborCost}
                step={1}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
          <ModalColumnSection>
            <ModalInputSection label='Average Parts Cost ($)'>
              <input
                type='text'
                name='averagePartsCost'
                className='form-control flex-fill'
                value={inputs.averagePartsCost}
                step={1}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        <ModalRowSection underline={false}>
          <ModalColumnSection>
            <ModalInputSection label='Average Labor Time (Hrs)'>
              <input
                type='text'
                name='averageLaborHours'
                className='form-control flex-fill'
                value={inputs.averageLaborHours}
                step={1}
                onChange={handleChange}
                onKeyDown={handleAllowOnlyNumbers}
              />
            </ModalInputSection>
          </ModalColumnSection>
        </ModalRowSection>
        {warnings.warningNextServiceDue && (
          <ModalWarning text='Warning - Next service due value is lower than / before current value' />
        )}
        <ModalRowSection underline={false}>
          <ModalWideColumnSection>
            <ModalInputSection label='Notes'>
              <input
                type='textarea'
                name='notes'
                className='form-control flex-fill'
                value={inputs.notes}
                onChange={handleChange}
              />
            </ModalInputSection>
          </ModalWideColumnSection>
        </ModalRowSection>
      </ModalBody>
      <ModalFooter onSubmit={submitModal} submitting={submitting} />
    </ModalFramework>,
    document.getElementById('app')
  );
}

export {NewTaskModal};
