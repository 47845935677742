import React, {useState, useEffect, useRef} from 'react';
import BarChart from '../../../components/BarChart';
import {useDispatch, useSelector} from 'react-redux';
import {customChartProperties, isNumeric} from '../../../app/utils';
import {IntelliSearchSelect} from '../../../components/IntelliSearchSelect';
import {genTotalMachineUsage} from '../dashboardDataProcessing';
import {
  KpiTooltip,
  ScorecardSelectHolder,
  StatsViewReportFiltersWrapper,
  StatsViewReportSortByWrapper,
  StatsViewReportMainWrapperStyle,
  StatsViewReportTooltipRowStyle,
} from '../dashboardUtils';
import {
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  InputLabel,
  Grid,
  Box,
  OutlinedInput,
  InputAdornment,
  Divider,
} from '@mui/material';
import ChartJS from 'chart.js/auto';
import annotationPlugin from 'chartjs-plugin-annotation';
ChartJS.register(annotationPlugin);

function KpiMachineUsage(props) {
  const loading = useSelector((state) => {
    return state.dashboard.loading;
  });
  const operationsData = useSelector((state) => {
    return state.dashboard.operationsData;
  });
  const vehicleSNDict = useSelector((state) => {
    return state.dashboard.vehicleSNDict;
  });
  const daysDiff = useSelector((state) => {
    return state.dashboard.daysDiff;
  });
  const filterOptions = useSelector((state) => {
    return state.dashboard.filterOptions;
  });
  const activeDevices = useSelector((state) => {
    return state.dashboard.activeDevices;
  });
  const unitsSystem = useSelector((state) => {
    return state.app.userSettings.general.units;
  });
  const smallScreen = useSelector((state) => {
    return state.framework.smallScreen;
  });
  const [totalMachineUsage, setTotalMachineUsage] = useState(null);
  const [datasets, setDatasets] = useState(null);
  const [displayedAxis, setDisplayedAxis] = useState('Total');
  const [hourDollarRate, setHourDollarRate] = useState('');
  const [fuelRate, setFuelRate] = useState(0);
  const [fuelCost, setFuelCost] = useState(0);
  const [fuelRateInput, setFuelRateInput] = useState('');
  const [fuelCostInput, setFuelCostInput] = useState('');

  const [usageFilters, setUsageFilters] = useState({
    usageVehicleSNs: [],
    usageVehicleType: [],
    usageVehicleCount: '25',
    usageVehicleSort: 'Desc',
  });

  const additionalInputRef = useRef();

  useEffect(() => {
    if (operationsData != null) {
      generateData();
    }
  }, [operationsData, vehicleSNDict, usageFilters, displayedAxis, activeDevices, hourDollarRate, fuelRate, fuelCost]);

  useEffect(() => {
    if (
      ((displayedAxis == 'Fuel' || displayedAxis == 'GHG') && !fuelRateInput) ||
      (displayedAxis == 'Cost' && !(hourDollarRate || (fuelCostInput && fuelRateInput)))
    ) {
      if(additionalInputRef && additionalInputRef.hasOwnProperty("current") && typeof(additionalInputRef.current) != "undefined"  ){
        additionalInputRef.current.scrollIntoView({behavior: 'smooth'});
      }
      
    }
  }, [displayedAxis]);

  useEffect(() => {
    if (props.pageStates?.displayedAxis) {
      setDisplayedAxis(props.pageStates?.displayedAxis);
    } else {
      setDisplayedAxis('Total');
    }
    if (props.pageStates?.fuelRate) {
      setFuelRate(parseFloat(props.pageStates?.fuelRate));
    }
    else{
      setFuelRate(0);
    }
    if (props.pageStates?.fuelCost) {
      setFuelCost(parseFloat(props.pageStates?.fuelCost));
      setHourDollarRate('');
    }
    else{
      setFuelCost(0)
    }
    if (props.pageStates?.hourDollarRate) {
      setHourDollarRate(parseFloat(props.pageStates?.hourDollarRate));
      setFuelCost(0);
    }
    if (props?.pageFilters) {
      setUsageFilters((values) => {
        return {
          ...values,
          ...props.pageFilters,
        };
      });
    }
  }, [props.pageStates, props.pageFilters]);

  function handleHourDollarRateChange(e) {
    if (isNumeric(e.target.value) || e.target.value == '') {
      setFuelCostInput('');
      setFuelCost(0);
      setHourDollarRate(e.target.value);
    }
  }

  function handleFuelRateChange(e) {
    const value = e.target.value;
    if (isNumeric(value) || value == '') {
      setFuelRateInput(value);
      // Always save value in metric
      setFuelRate(unitsSystem == 'imperial' ? parseFloat(value * 3.785) : parseFloat(value));
    }
  }

  function handleFuelCostChange(e) {
    const value = e.target.value;
    if (isNumeric(value) || value == '') {
      setHourDollarRate('');
      // Always save value in metric
      setFuelCostInput(value);
      setFuelCost(unitsSystem == 'imperial' ? parseFloat(value / 3.785) : parseFloat(value));
    }
  }

  function generateData() {
    // Generate data
    const totalMachineUsageTemp = genTotalMachineUsage(
      operationsData.vehiclesObject,
      usageFilters,
      daysDiff,
      operationsData.vehicleHoursByTypeObject,
      vehicleSNDict,
      activeDevices,
      unitsSystem,
      hourDollarRate,
      fuelRate,
      fuelCost
    );
    if (Object.keys(operationsData.vehiclesObject).length > 0) {
      setTotalMachineUsage(totalMachineUsageTemp);
    } else {
      setTotalMachineUsage(null);
    }

    const tempDatasets = [];
    totalMachineUsageTemp.datasets.forEach((setObj) => {
      if (setObj.id == displayedAxis) {
        tempDatasets.push(setObj);
      }
    });
    setDatasets(tempDatasets);
  }

  function handleVehiclesFilters(value, selectName) {
    setUsageFilters((values) => {
      return {...values, [selectName]: value};
    });
  }

  function handleVehiclesFiltersOld(select) {
    // Update filters state based on values selected in drop down selects
    const selected = [];
    for (let i = 0; i < select.options.length; i++) {
      if (select.options[i].attributes.getNamedItem('selected')) {
        selected.push(select.options[i].value);
      }
    }

    const value = select.multiple ? selected : selected[0];
    const name = select.name;
    setUsageFilters((values) => {
      return {...values, [name]: value};
    });
  }

  function machineUsageGraph(rateCostText = null) {
    let graphTitle;
    let axisTitle;

    switch (displayedAxis) {
      case 'Total':
        graphTitle = 'Total Machine Usage';
        axisTitle = 'Usage Hours';
        break;
      case 'Distance':
        graphTitle = 'Total Machine Travel Distance';
        axisTitle = unitsSystem == 'imperial' ? 'Distance (mi)' : 'Distance (km)';
        break;
      case 'Avg':
        // Deprecated, but keeping for old scorecards that may need it
        graphTitle = 'Average Machine Hours / Day';
        axisTitle = 'Hours';
        break;
      case 'Fuel':
        graphTitle = 'Total Fuel Consumption';
        axisTitle = unitsSystem == 'imperial' ? 'Fuel Consumption (Gal)' : 'Fuel Consumption (L)';
        break;
      case 'GHG':
        graphTitle = 'Total GHG emissions';
        axisTitle = 'Emissions (Tonnes)';
        break;
      case 'Cost':
        if (hourDollarRate != '') {
          graphTitle = 'Total Machine Operating Cost';
          axisTitle = 'Machine Operating Cost ($)';
        } else {
          graphTitle = 'Total Fuel Cost';
          axisTitle = 'Fuel Cost ($)';
        }
        break;
      default:
        graphTitle = 'Total Machine Hours';
        axisTitle = 'Usage Hours';
    }

    return (
      <div style={{height: '60vh', minHeight: '500px'}}>
        {/* Chart */}
        <BarChart
          data={{...totalMachineUsage, datasets: datasets}}
          options={{
            maintainAspectRatio: false,
            plugins: {
              title: {
                text: graphTitle,
                display: true,
                font: {
                  size: customChartProperties.titleFontSize,
                  lineHeight: customChartProperties.lineHeight,
                },
              },
              subtitle: {
                display: rateCostText != null,
                text: rateCostText,
              },
              legend: {
                onClick: (e) => {
                  return false;
                },
                labels: {
                  font: {
                    size: customChartProperties.legendFontSize,
                  },
                },
              },
            },
            scales: {
              leftAxis: {
                title: {
                  text: axisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                display: displayedAxis != 'Avg',
                position: 'left',
              },
              rightAxis: {
                title: {
                  text: axisTitle,
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
                type: 'linear',
                display: displayedAxis == 'Avg',
                position: 'right',
              },
              x: {
                ticks: {
                  autoSkip: totalMachineUsage.labels.length > customChartProperties.autoSkipLimit,
                  maxRotation: 90,
                  minRotation: 90,
                  font: {
                    size: customChartProperties.xLabelFontSize,
                    lineHeight: customChartProperties.lineHeight,
                  },
                },
                title: {
                  text: 'Vehicle',
                  display: true,
                  font: {
                    size: customChartProperties.axisTitleFontSize,
                    weight: customChartProperties.axisTitleFontWeight,
                  },
                },
              },
            },
          }}
        />
      </div>
    );
  }

  // Check if loading
  if (loading) {
    return <CircularProgress className='mt-4 mx-auto d-block' size={200} />;
  }

  if (props.graphOnly) {

    let rateCostText = '';
    switch (displayedAxis) {
      case 'Fuel':
      case 'GHG':
        const fuelRateString =
          unitsSystem == 'imperial'
            ? `${parseFloat((fuelRate / 3.785).toFixed(2))} Gal/Hr`
            : `${fuelRate.toFixed(2)} L/Hr`;
        rateCostText += `Fuel Rate: ${fuelRateString}`;
        break;
      case 'Cost':
        if (hourDollarRate != '') {
          rateCostText += `Machine Operating Rate: $${parseFloat(hourDollarRate).toFixed(2)}/hr`;
        } else {
          const fuelRateString =
            unitsSystem == 'imperial'
              ? `${parseFloat((fuelRate / 3.785).toFixed(2))} Gal/Hr`
              : `${fuelRate.toFixed(2)} L/Hr`;
          rateCostText += `Fuel Rate: ${fuelRateString}`;
          const fuelCostString =
            unitsSystem == 'imperial'
              ? `${parseFloat((fuelCost * 3.785).toFixed(2))} $/Gal`
              : `${fuelCost.toFixed(2)} $/L`;
          rateCostText += ` | Fuel Cost: ${fuelCostString}`;
        }
        break;
      default:
        rateCostText = null;
    }

    return (
      <React.Fragment>
        {/* Tooltip */}
        <div className='col-12 flex-col px-0'>
          <KpiTooltip selectedSubset='kpiMachineUsage' />
        </div>
        <div className='col-lg-8 flex-col text-center px-0 align-items-center my-auto'>
          {!totalMachineUsage ? (
            <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
          ) : (
            machineUsageGraph(rateCostText)
          )}
        </div>
      </React.Fragment>
    );
  }

  return (
    <Box sx={StatsViewReportMainWrapperStyle}>
      {/* Tooltip and Add to Scorecard*/}
      <Box sx={StatsViewReportTooltipRowStyle}>
        {/* Tooltip */}
        <KpiTooltip selectedSubset='kpiMachineUsage' />
        {/* Add to Scorecard Button & Modal */}
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
          }}
        >
          <ScorecardSelectHolder
            autofillPageName={'Machine Usage'}
            pageFilters={usageFilters}
            pageStates={{
              displayedAxis: displayedAxis,
              fuelRate: fuelRate,
              fuelCost: fuelCost,
              hourDollarRate: hourDollarRate,
            }}
          />
        </Box>
      </Box>

      {!totalMachineUsage ? (
        <div style={{textAlign: 'center', marginTop: '100px'}}> No data available</div>
      ) : (
        <React.Fragment>
          {/* Filters */}
          <StatsViewReportFiltersWrapper key={`vehicleUsageFilter`}>
            {/* Task Filter */}
            <IntelliSearchSelect
              allNoneEnabled
              inputLabel={"Vehicles"}
              id='dashboard-vehicles-usageVehicleSNs-filter'
              name='usageVehicleSNs'
              multiple={true}
              search={true}
              value={usageFilters.usageVehicleSNs}
              options={filterOptions.vehicles.vehicleSNs.map((opt) => {
                return {"label": opt.text, "value":opt.value}
              })}
              onChange={(s) => {
                handleVehiclesFilters(s, "usageVehicleSNs");
              }}
              placeholder='Filter for Vehicles'
            />
            <IntelliSearchSelect
              allNoneEnabled
              inputLabel={"Vehicle Type"}
              id='dashboard-vehicles-usageVehicleType-filter'
              name='usageVehicleType'
              multiple={true}
              search={true}
              value={usageFilters.usageVehicleType}
              options={filterOptions.vehicles.vehicleType.map((opt) => {
                return {"label": opt.text, "value":opt.value}
              })}
              onChange={(s) => {
                handleVehiclesFilters(s, "usageVehicleType");
              }}
              placeholder='Filter for Vehicle Type'
            />
          </StatsViewReportFiltersWrapper>
          {/* Sort By  small screen*/}
          {smallScreen && <StatsViewReportSortByWrapper key={`vehicleUsageSort`}>
            <IntelliSearchSelect
              id='dashboard-vehicles-usageVehicleSort-filter'
              name='usageVehicleSort'
              multiple={false}
              value={usageFilters.usageVehicleSort}
              options={filterOptions.vehicles.usageVehicleSort.map((opt) => {return {"label": opt.text, "value":opt.value}})}
              onChange={(s) => {
                handleVehiclesFilters(s, "usageVehicleSort");
              }}
              placeholder='Sorting method'
            />
            <IntelliSearchSelect
              id='dashboard-vehicles-usageVehicleCount-filter'
              name='usageVehicleCount'
              multiple={false}
              value={usageFilters.usageVehicleCount}
              options={filterOptions.vehicles.vehicleCount.map((opt) => {return {"label": opt.text, "value":opt.value}})}
              onChange={(s) => {
                handleVehiclesFilters(s, "usageVehicleCount");
              }}
              placeholder={`Show  All Vehicles`}
            />
          </StatsViewReportSortByWrapper>}
          {/* Non small screen Sort By and additional y axis together */}
          
          {!smallScreen && 
          <Grid sx={{display:'flex'}} container>
            <Grid item xs={6} sx={{display: 'flex', justifyContent: 'start', padding: '20px', alignItems:'end'}} ref={additionalInputRef}>
            {(displayedAxis == 'Fuel' || displayedAxis == 'Cost' || displayedAxis == 'GHG') && (
              <Box
                sx={{
                  '.MuiInputBase-input': {
                    padding: '5px',
                  },
                  display: 'flex',
                  padding: '10px',
                  justifyContent: 'start',
                  alignItems: 'center',
                  boxShadow:
                    ((displayedAxis == 'Fuel' || displayedAxis == 'GHG') && !fuelRateInput) ||
                    (displayedAxis == 'Cost' && !(hourDollarRate || (fuelCostInput && fuelRateInput)))
                      ? '0px 3px 10px 0px #ff9966'
                      : '0px 3px 10px 0px #aaaaaa',
                  borderRadius: '4px',
                }}
              >
                <FormControl sx={{maxWidth: '160px'}}>
                  <InputLabel shrink>Fuel Rate</InputLabel>
                  <OutlinedInput
                    endAdornment={
                      <InputAdornment position='end'>{unitsSystem == 'imperial' ? 'Gal' : 'L'}/Hr</InputAdornment>
                    }
                    notched
                    label='Fuel Rate'
                    inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                    size='small'
                    onChange={handleFuelRateChange}
                    value={fuelRateInput}
                  />
                </FormControl>
                {displayedAxis == 'Cost' && (
                  <React.Fragment>
                    <FormControl sx={{maxWidth: '160px'}}>
                      <InputLabel shrink>Fuel Cost</InputLabel>
                      <OutlinedInput
                        endAdornment={
                          <InputAdornment position='end'>$/{unitsSystem == 'imperial' ? 'Gal' : 'L'}</InputAdornment>
                        }
                        notched
                        label='Fuel Cost'
                        size='small'
                        onChange={handleFuelCostChange}
                        value={fuelCostInput}
                      />
                    </FormControl>
                    <Divider orientation='vertical' flexItem>
                      or
                    </Divider>
                    <FormControl sx={{maxWidth: '160px'}}>
                      <InputLabel shrink>Hour Rate</InputLabel>
                      <OutlinedInput
                        endAdornment={<InputAdornment position='end'>$/Hr</InputAdornment>}
                        notched
                        label='Hour Rate'
                        size='small'
                        onChange={handleHourDollarRateChange}
                        value={hourDollarRate}
                      />
                    </FormControl>
                  </React.Fragment>
                )}
              </Box>
            )}
          </Grid>
          <Grid item xs={6}>
          <StatsViewReportSortByWrapper  key={`vehicleUsageSort`}>
            <IntelliSearchSelect
              id='dashboard-vehicles-usageVehicleSort-filter'
              name='usageVehicleSort'
              multiple={false}
              value={usageFilters.usageVehicleSort}
              options={filterOptions.vehicles.usageVehicleSort.map((opt) => {return {"label": opt.text, "value":opt.value}})}
              onChange={(s) => {
                handleVehiclesFilters(s, "usageVehicleSort");
              }}
              placeholder='Sorting method'
            />
            <IntelliSearchSelect
              id='dashboard-vehicles-usageVehicleCount-filter'
              name='usageVehicleCount'
              multiple={false}
              value={usageFilters.usageVehicleCount}
              options={filterOptions.vehicles.vehicleCount.map((opt) => {return {"label": opt.text, "value":opt.value}})}
              onChange={(s) => {
                handleVehiclesFilters(s, "usageVehicleCount");
              }}
              placeholder={`Show  All Vehicles`}
            />
          </StatsViewReportSortByWrapper>
          </Grid>
        </Grid>
          }
          {/* Graph and XY Axis Control */}
          <Grid container sx={{display: 'flex', flexWrap: 'wrap', justifyContent: 'center'}} spacing={0}>
            {/* Y-Axis Controls */}
            <Grid
              item
              xs={2}
              lg={1}
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Box
                sx={{
                  '.MuiInputBase-input': {
                    padding: '5px',
                  },
                  display: 'flex',
                  flexDirection: 'row',
                  position: 'relative',
                  transformOrigin: 'center',
                  transform: 'rotate(270deg)',
                  margin: 'auto',
                  justifyContent: 'center',
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    textAlign: 'center',
                    boxShadow: '-5px -5px 10px 0px #aaaaaa',
                    borderRadius: '4px',
                  }}
                >
                  <FormControl sx={{m: 1}}>
                    <InputLabel sx={{maxWidth:'none'}}>Measure by</InputLabel>
                    <Select
                      sx={{padding:'3px'}}
                      size='small'
                      label='Measure by'
                      value={displayedAxis}
                      onChange={(e) => {
                        setDisplayedAxis(e.target.value);
                      }}
                    >
                      <MenuItem value={'Total'}>{'Total Hours'}</MenuItem>
                      <MenuItem value={'Distance'}>{'Total Distance'}</MenuItem>
                      <MenuItem value={'Fuel'}>{'Fuel'}</MenuItem>
                      <MenuItem value={'Cost'}>{'Cost'}</MenuItem>
                      <MenuItem value={'GHG'}>{'GHG'}</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </Box>
            </Grid>
            {/* Graph */}
            <Grid item xs={10} lg={9} sx={{textAlign: 'center'}}>
              {machineUsageGraph()}
            </Grid>
            {/* Y-Axis Additional Input */}
            {smallScreen && <Grid item xs={12} md={8} sx={{display: 'flex', justifyContent: 'start'}} ref={additionalInputRef}>
              {(displayedAxis == 'Fuel' || displayedAxis == 'Cost' || displayedAxis == 'GHG') && (
                <Box
                  sx={{
                    '.MuiInputBase-input': {
                      padding: '5px',
                    },
                    display: 'flex',
                    padding: '10px',
                    justifyContent: 'start',
                    alignItems: 'center',
                    boxShadow:
                      ((displayedAxis == 'Fuel' || displayedAxis == 'GHG') && !fuelRateInput) ||
                      (displayedAxis == 'Cost' && !(hourDollarRate || (fuelCostInput && fuelRateInput)))
                        ? '0px 3px 10px 0px #ff9966'
                        : '0px 3px 10px 0px #aaaaaa',
                    borderRadius: '4px',
                  }}
                >
                  <FormControl sx={{maxWidth: '160px'}}>
                    <InputLabel shrink>Fuel Rate</InputLabel>
                    <OutlinedInput
                      endAdornment={
                        <InputAdornment position='end'>{unitsSystem == 'imperial' ? 'Gal' : 'L'}/Hr</InputAdornment>
                      }
                      notched
                      label='Fuel Rate'
                      inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                      size='small'
                      onChange={handleFuelRateChange}
                      value={fuelRateInput}
                    />
                  </FormControl>
                  {displayedAxis == 'Cost' && (
                    <React.Fragment>
                      <FormControl sx={{maxWidth: '160px'}}>
                        <InputLabel shrink>Fuel Cost</InputLabel>
                        <OutlinedInput
                          endAdornment={
                            <InputAdornment position='end'>$/{unitsSystem == 'imperial' ? 'Gal' : 'L'}</InputAdornment>
                          }
                          notched
                          label='Fuel Cost'
                          size='small'
                          onChange={handleFuelCostChange}
                          value={fuelCostInput}
                        />
                      </FormControl>
                      <Divider orientation='vertical' flexItem>
                        or
                      </Divider>
                      <FormControl sx={{maxWidth: '160px'}}>
                        <InputLabel shrink>Hour Rate</InputLabel>
                        <OutlinedInput
                          endAdornment={<InputAdornment position='end'>$/Hr</InputAdornment>}
                          notched
                          label='Hour Rate'
                          size='small'
                          onChange={handleHourDollarRateChange}
                          value={hourDollarRate}
                        />
                      </FormControl>
                    </React.Fragment>
                  )}
                </Box>
              )}
            </Grid>}
            <Grid item md={3} display={{xs: 'none', md: 'block'}}></Grid>
          </Grid>
        </React.Fragment>
      )}
    </Box>
  );
}

export {KpiMachineUsage};
